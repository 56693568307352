import { useMemo, useState } from "react";
import classNames from "./prospectusPage.module.scss";
import MainLayout from "../../layouts/MainLayout";
import VirtualProspectusAnalysis from "../../components/VirtualProspectusAnalysis";
import VirtualProspectusVaults from "../../components/VirtualProspectusVaults";

function ProspectusPage() {
  const [tab, setTab] = useState("Snapshot");
  const tabContent = useMemo(() => {
    switch (tab) {
      case "Snapshot":
        return <VirtualProspectusAnalysis />;
      case "Vaults":
        return <VirtualProspectusVaults />;
      //   case "Business":
      //     return <VirtualProspectusBusiness />;
      default:
        return null;
    }
  }, [tab]);
  return (
    <MainLayout className={classNames.prospectusPage}>
      <>
        {tabContent}
        <div className={classNames.tabs}>
          <div
            className={`${classNames.tabItem} ${
              classNames[(tab === "Snapshot").toString()]
            }`}
            onClick={() => {
              setTab("Snapshot");
            }}
          >
            Snapshot
          </div>
          <div
            className={`${classNames.tabItem} ${
              classNames[(tab === "Vaults").toString()]
            }`}
            onClick={() => {
              setTab("Vaults");
            }}
          >
            Vaults
          </div>
          <div
            className={`${classNames.tabItem} ${
              classNames[(tab === "Trades").toString()]
            }`}
            onClick={() => {
              setTab("Trades");
            }}
          >
            Trades
          </div>
          <div
            className={`${classNames.tabItem} ${
              classNames[(tab === "AboutTheBanker").toString()]
            }`}
            onClick={() => {
              setTab("AboutTheBanker");
            }}
          >
            About The Banker
          </div>
          <div
            className={`${classNames.tabItem} ${
              classNames[(tab === "OfferingStatement").toString()]
            }`}
            onClick={() => {
              setTab("OfferingStatement");
            }}
          >
            Offering Statement
          </div>
          <div
            className={`${classNames.tabItem} ${
              classNames[(tab === "Legal").toString()]
            }`}
            onClick={() => {
              setTab("Legal");
            }}
          >
            Legal
          </div>
        </div>
      </>
    </MainLayout>
  );
}

export default ProspectusPage;
