import React, { useState, useEffect, useRef, useContext } from 'react'
import './commonNav.scss'
import "./topcard.scss"
import inrgroup from "../../static/images/commonnav/inrgroup.svg";
import DotsIcon from "../../static/images/commonnav/groupdotsicon.svg";
import MailIcon from "../../static/images/commonnav/mailicon.svg";
import IndIcon from "../../static/images/commonnav/indicon.svg";
import CloseIcon from "../../static/images/commonnav/closeicon.svg";
import IndianOTCIcon from "../../static/images/commonnav/indianotcicon.svg";
import TaxChainIcon from "../../static/images/commonnav/taxchainicon.svg";
import IndianInvestorIcon from "../../static/images/commonnav/indianinvestoricon.svg";
import IndiaCoinsIcon from "../../static/images/commonnav/indiacoinsicon.svg";
import IndianMarketIcon from "../../static/images/commonnav/metaverseicon.svg"
import BhararTrustIcon from "../../static/images/commonnav/bharattrusticon.svg";
import InstaIcon from "../../static/images/commonnav/instasvg.svg";
import DiscordIcon from "../../static/images/commonnav/discordsvg.svg";
import YoutubeIcon from "../../static/images/commonnav/youtubesvg.svg";
import EmailIcon from "../../static/images/commonnav/mailsvg.svg";
import { MainContext } from '../context/context';

export default function Carer() {
  const {selectnav, setselectnav} = useContext(MainContext)
  const [isMenuOpened, SetIsMenuOpened] = useState("none")
  const [RightCard, SetRightCard] = useState("none")
  const [pagemask, setpagemask] = useState("")
  const [dotsdisp, setdotsdisp] = useState("")
  const [closedisp, setclosedisp] = useState("none")
  const [theicon, settheicon] = useState("none")
  const wrapperRef = useRef();
  const wrapperRef1 = useRef();
  useOutsideAlerterLeft(wrapperRef);
  useOutsideAlerterRight(wrapperRef1);

  function useOutsideAlerterLeft(ref) {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */

      function handleClickOutside(event) {
        // console.log(ref.current, event.target, "kwjbfkwjbefc");
        if (ref.current && !ref.current.contains(event.target)) {
          closemenu(event)
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  function useOutsideAlerterRight(ref) {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */

      function handleClickOutside(event) {
        // console.log(ref.current, event.target, "kwjbfkwjbefc");
        if (ref.current && !ref.current.contains(event.target)) {
          rightclose(event)
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  let carddata = [
    {
      "image": IndianOTCIcon,
      "title": "IndianOTC",
      "desc": "Digital Asset Trading Hub"
    },
    {
      "image": TaxChainIcon,
      "title": "TaxChains",
      "desc": "Crypto Tax Software"
    },
    {
      "image": IndianInvestorIcon,
      "title": "IndianInvestor",
      "desc": "Wealth Management System"
    },
    {
      "image": IndiaCoinsIcon,
      "title": "IndiaCoins",
      "desc": "India`s NFT Marketplace"
    },
    {
      "image": IndianMarketIcon,
      "title": "IndianMarket",
      "desc": "Financial Media & News"
    },
    {
      "image": BhararTrustIcon,
      "title": "BharatTrust",
      "desc": "Social Investing Platform"
    },

  ]

  let rightdata = [
    {
      "image": InstaIcon,
      "title": "Instagram",
      "desc": "@inr.group"
    },
    {
      "image": DiscordIcon,
      "title": "Discord",
      "desc": "Join Server"
    },
    {
      "image": YoutubeIcon,
      "title": "Youtube",
      "desc": "Go To Channel"
    },
    {
      "image": EmailIcon,
      "title": "Email",
      "desc": "support@inr.group"
    },
  ]


  const openmenu = (e) => {
    setpagemask("pagemask")
    SetIsMenuOpened("block")
    setdotsdisp("none")
    setclosedisp("")
    setselectnav(true)
  }
  const closemenu = (e) => {
    setpagemask("")
    SetIsMenuOpened("none")
    setdotsdisp("")
    setclosedisp("none")
    setselectnav(false)
  }

  const openrightcard = (e) => {
    SetRightCard("")
    setpagemask("pagemask")
    settheicon("")
    setselectnav(true)
  }

  const rightclose = (e) => {
    SetRightCard("none")
    setpagemask("")
    settheicon("none")
    setselectnav(false)
  }


  return (
    <div className='carres'>
      <div className='vl-top'>
        <a href="https://inr.group/" alt="">
          <img src={inrgroup} alt="nvest-icon" key="1" className='left-icon' style={{height:'1.2rem'}} ref={wrapperRef}/>
        </a>
      </div>
      <div className='vl-top'>
        <img src={DotsIcon} alt="dots-icon"  key="2" className='left-icon' onClick={openmenu} style={{height:'1.2rem'}}/>
      </div>

      <div className='right-icons'>
        <div className='vl-top1'>
          <img src={MailIcon} alt="mail-icon"  key="3" className='right-logo' onClick={openrightcard} style={{height:'1.2rem'}} ref={wrapperRef1}/>
        </div>
        <div className='vl-top1'>
          <img src={IndIcon} alt="ind-icon"  key="4"  className='right-logo' style={{height:'1.2rem'}}/>
        </div>
      </div>
      {/* <h5 style={{ fontWeight: "bold", marginRight: "35px" }}><img src={te} style={{ marginRight: '10px' }} />For Investors
      </h5>
      <h5 style={{ opacity: 0.5 }}><img src={re} style={{ marginRight: '10px' }} />For Entrepreneurs</h5> */}
      <div className={pagemask}></div>

      <div className='sidemenu' style={{ display: isMenuOpened }}>
        <div className='side-text'>
          <img className="close-icon" src={CloseIcon} alt="close-icon" className='close-img' onClick={closemenu} style={{ display: closedisp, paddingBottom:'64px' }} />
          <p className="card-title">One Account. Mutiple Advantages</p>
          <p className='card-desc'>With An IndianOTC Account You Also Get Access To The INR Group Apps</p>
          {carddata.map(e => {
            return (
              <>
                <br />
                <div className='card-data'>
                  <img src={e.image} alt="indian-otc" className='company-logo' />
                  <div className='card-text'>
                    <p className='comp-title'>{e.title}</p>
                    <p className='comp-desc'>{e.desc}</p>
                  </div>
                </div>
              </>
            )
          })}
        </div>
      </div>
      {/* <div className='right-card'> */}
      <div className='rightmenu' style={{ display: RightCard }}>
        <img className="close-icon" src={CloseIcon} alt="close-icon" className='close-img-right' onClick={rightclose} style={{ display: theicon , paddingBottom:'64px'}} />
        <div className='side-text1'>
          <p className="right-card-title">Connect With Us</p>
          <p className='right-card-desc'>We Would Love To Hear From You</p>
          {rightdata.map(e => {
            return (
              <>
                <br />
                <div className='card-data'>
                  <img src={e.image} alt="indian-otc" className='company-logo' />
                  <div className='card-text'>
                    <p className='comp-title'>{e.title}</p>
                    <p className='comp-desc'>{e.desc}</p>
                  </div>
                </div>
              </>
            )
          })}
        </div>
      </div>
      {/* </div> */}



    </div>
  )
}
