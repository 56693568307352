import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import classNames from "./fundsMarketSubTypesPage.module.scss";
import MainLayout from "../../layouts/MainLayout";
import { useAllFundSubTypes, useAllFundTypes } from "../../queryHooks";
import FundsMarketPathsTable from "../../components/FundsMarketPathsTable";

function FundsMarketSubTypesPage(): JSX.Element {
  const { subtype } = useParams();
  const { data: subTypeList = [] } = useAllFundTypes();
  const { data: pathList = [], isLoading } = useAllFundSubTypes(subtype);
  const [type, setType] = useState<any>();

  useEffect(() => {
    setType(subTypeList.filter((type: any) => type.code === subtype)?.[0]);
  }, [subTypeList, subtype]);

  return (
    <MainLayout className={classNames.fundsMarketSubTypesPage}>
      <>
        <div className={classNames.topArea}>
          <div className={`${classNames.cardSub} ${classNames.company}`}>
            <div className={classNames.value}>{pathList?.length}</div>
            <div className={classNames.label}>Companies</div>
          </div>
          <div className={classNames.centerLogoName}>
            <img src={type?.icon} alt="" className={classNames.logo} />
            <span>{type?.name}</span>
          </div>
          <div className={`${classNames.cardSub} ${classNames.mcap}`}>
            <div className={classNames.value}>--</div>
            <div className={classNames.label}>Market Cap</div>
          </div>
        </div>
        <div className={classNames.spacer} />
        <FundsMarketPathsTable pathList={pathList} isLoading={isLoading} />
      </>
    </MainLayout>
  );
}

export default FundsMarketSubTypesPage;
