import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import classNames from "./topNavbarAboutSubMenu.module.scss";
import p2p from "../../static/images/sidebarIcons/p2p.svg";
import homeIcon from "../../static/images/sidebarIcons/about/home.svg";
import products from "../../static/images/sidebarIcons/about/products.svg";
import company from "../../static/images/sidebarIcons/about/company.svg";
import platforms from "../../static/images/sidebarIcons/about/platforms.svg";
import contactus from "../../static/images/sidebarIcons/about/contactus.svg";
import { selectLoginData } from "../../app/loginSlice";
import btc from "../../static/images/actionIcons/btc.svg";
import cash from "../../static/images/actionIcons/cash.svg";
import eth from "../../static/images/actionIcons/eth.svg";
import inr from "../../static/images/actionIcons/inr.svg";
import usdt from "../../static/images/actionIcons/usdt.svg";
import wallet from "../../static/images/actionIcons/wallet.svg";
import tokenSwapIcon from "../../static/images/sidebarIcons/tokeng.svg";

const SIDEBAR_LIST = [
  {
    label: "Home",
    icon: homeIcon,
    link: "/home",
  },
  {
    label: "Company",
    icon: company,
    link: "/company",
    login: false,
  },
  {
    label: "Products",
    icon: products,
    link: "",
    disable: true,
    login: false,
  },
  {
    label: "Platforms",
    icon: platforms,
    link: "",
    login: false,
    disable: true,
  },
  {
    label: "Contact Us",
    icon: contactus,
    link: "/contact",
    login: false,
  },
];

const ACTIONS_LIST = [
  {
    title: "How To Get Started",
    subTitle: "Getting Started",
    icon: '',
  },
  {
    title: "What Are The Fees?",
    subTitle: "Fees",
    icon: '',
  },
  {
    title: "It Crypto Safe?",
    subTitle: "Security",
    icon: '',
  },
  {
    title: "How To Login",
    subTitle: "Getting Started",
    icon: '',
  },
  {
    title: "What Is CryptoBrokers?",
    subTitle: "CryptoBrokers",
    icon: '',
  },
  {
    title: "What Is IndianOTC?",
    subTitle: "Getting Started",
    icon: '',
  },
  {
    title: "Download Mobile Apps",
    subTitle: "Getting Started",
    icon: '',
  },
  {
    title: "What Are FundCoins?",
    subTitle: "FundCoins",
    icon: '',
  },
  {
    title: "Why IndianOTC?",
    subTitle: "Getting Started",
    icon: '',
  },
];

function AboutSubMenu({
  openTab,
  setOpenTab,
  setLoginedPath,
  setLoginModalOpen,
}: {
  openTab: boolean;
  setOpenTab: { (value: string): void };
  setLoginedPath: { (value: string): void };
  setLoginModalOpen: { (value: boolean): void };
}) {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { email } = useSelector(selectLoginData);
  return (
    <div
      className={`${classNames.otcSubMenuWrap} ${
        classNames[openTab.toString()]
      }`}
    >
      {openTab && (
        <div className={classNames.overlay} onClick={() => setOpenTab("")} />
      )}
      <div className={classNames.otcSubMenu}>
        <div className={classNames.subMenuList}>
          {SIDEBAR_LIST.map((menu, i) => (
            <div
              key={menu.label}
              onClick={() => {
                if (!menu.disable) {
                  if (menu.login && !email) {
                    setLoginedPath(`/about${menu.link}`);
                    setLoginModalOpen(true);
                    setOpenTab("");
                  } else {
                    navigate(`/about${menu.link}`);
                  }
                }
              }}
              className={`${
                classNames[
                  (
                    pathname === `/about${menu.link}` ||
                    (pathname === "/about" && menu.link === "/home")
                  ).toString()
                ]
              } ${classNames[menu.disable ? "dis" : ""]} ${
                classNames.sideMenu
              }`}
            >
              <img src={menu.icon} alt="" />
              <span>{menu.label}</span>
            </div>
          ))}
        </div>
        <div className={classNames.tabDetailContent}>
          <div className={classNames.titlle}>Beginner FAQ’s</div>
          <div className={classNames.subtitle}>
          Speak To A Specialist By Clicking <strong>Here</strong>
          </div>
          <div className={classNames.actionList}>
            {ACTIONS_LIST.map((action: any, i) => (
              <div className={classNames.action} key={i}>
                {/* <img src={action.icon} alt="" className={classNames.icon} /> */}
                <div className={classNames.icon}>  </div>
                <div className={classNames.detail}>
                  <div className={classNames.actName}>{action.title}</div>
                  <div className={classNames.actDesc}>{action.subTitle}</div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default AboutSubMenu;
