import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";
import { GX_AUTH_URL } from "../configs";
import { RootState } from "./store";

export interface LoginState {
  email: string;
  token: string;
  accesToken: string;
  deviceId: string;
  refreshToken: string;
}

const initialState: LoginState = {
  email: "",
  token: "",
  accesToken: "",
  deviceId: "",
  refreshToken: "",
};

export const loginSlice = createSlice({
  name: "loginData",
  initialState,
  reducers: {
    logout: (state) => {
      state.email = "";
      state.token = "";
      state.accesToken = "";
      state.deviceId = "";
      state.refreshToken = "";
    },
    login: (state, action: PayloadAction<LoginState>) => {
      state.email = action.payload.email;
      state.token = action.payload.token;
      state.accesToken = action.payload.accesToken;
      state.deviceId = action.payload.deviceId;
      state.refreshToken = action.payload.refreshToken;
    },
    updateToken: (
      state,
      action: PayloadAction<{ token: string; accesToken: string }>
    ) => {
      state.token = action.payload.token;
      state.accesToken = action.payload.accesToken;
    },
  },
});

export const { logout, login, updateToken } = loginSlice.actions;

// Thunk To Update Token
export const updateTokenAsync =
  (deviceId: string, refreshToken: string) => (dispatch: any) => {
    axios
      .post(`${GX_AUTH_URL}/gx/user/refresh/tokens`, {
        refreshToken,
        device_key: deviceId,
      })
      .then((resp) => {
        const { data } = resp;
        if (data.status) {
          dispatch(
            updateToken({
              token: data?.idToken || "",
              accesToken: data?.accessToken || "",
            })
          );
        } else {
          dispatch(logout());
        }
      })
      .catch((error) => {
        dispatch(logout());
      });
  };

export const selectLoginData = (state: RootState) => state.loginData;

export default loginSlice.reducer;
