/* eslint-disable @typescript-eslint/no-unused-vars */
import axios from "axios";
import { useQuery } from "react-query";
import CryptoJS from "crypto-js";
import { APP_CODE, DISP_CURRENCY, TERMINAL_ID } from "../configs";

const getOTCDetail = async (): Promise<any> => {
  const { data } = await axios.get(
    `https://comms.globalxchange.com/gxb/apps/otcdesk/get?app_code=${APP_CODE}`
  );
  return data?.otcDesks[0];
};

export const useOTCDetail = () => {
  const query = useQuery(["getOTCDetail"], getOTCDetail);
  return query;
};

const getUserDetails = async ({ queryKey }: any): Promise<any> => {
  const [_key, email] = queryKey;
  const { data } = await axios.post(
    "https://comms.globalxchange.com/get_affiliate_data_no_logs",
    {
      email: email,
    }
  );
  return data[0];
};

export const useUserDetails = (email?: string) => {
  const query = useQuery(["getUserDetails", email], getUserDetails);
  return query;
};

const getCoinsData = async ({ queryKey }: any): Promise<any> => {
  const [_key, { appCode, type, displayCurrency }] = queryKey;
  const { data } = await axios.post(
    "https://comms.globalxchange.com/coin/vault/service/coins/get",
    {
      app_code: appCode,
      type,
      displayCurrency,
    }
  );
  return data?.coins_data;
};

export const useCoinsData = (
  appCode: string,
  type?: string,
  displayCurrency?: string
) => {
  const query = useQuery(
    ["getCoinsData", { appCode, type, displayCurrency }],
    getCoinsData
  );
  return query;
};

const getPairQuotes = async (): Promise<any> => {
  const { data } = await axios.get(
    `https://api.terminals.dev/terminal/pair/byQuoteAsset/byTID/${TERMINAL_ID}`
  );
  return data?.pairs;
};

export const usePairQuotes = () => useQuery(["getPairQuotes"], getPairQuotes);

const getPairByQuotes = async ({ queryKey }: any): Promise<any> => {
  const [_key, { quote }] = queryKey;
  const { data } = await axios.get(
    `https://api.terminals.dev/terminal/pair/prices?id=${TERMINAL_ID}&toAsset=${quote}`
  );
  return data;
};

export const usePairByQuotes = (quote: string) =>
  useQuery(["getPairByQuotes", { quote }], getPairByQuotes);

const getMarketCoinsList = async ({ queryKey }: any) => {
  const [_key, { type, displayCurrency }] = queryKey;
  const { data } = await axios.post(
    "https://comms.globalxchange.com/coin/vault/service/coins/get",
    {
      app_code: "indianinvestor",
      type,
      displayCurrency: displayCurrency,
    }
  );
  return data?.coins_data;
};

export const useMarketCoinsList = (type: string, displayCurrency: string) => {
  const query = useQuery(
    ["getMarketCoinsList", { type, displayCurrency }],
    getMarketCoinsList
  );
  return query;
};

const getRegisterdUsers = async () => {
  const { data } = await axios.get(
    "https://comms.globalxchange.com/listUsernames"
  );
  if (data.status) {
    let bytes = CryptoJS.Rabbit.decrypt(data.payload, "gmBuuQ6Er8XqYBd");
    let jsonString = bytes.toString(CryptoJS.enc.Utf8);
    let result_obj = JSON.parse(jsonString);
    return result_obj;
  }
};

export const useRegisterdUsers = () =>
  useQuery(["getRegisterdUsers"], getRegisterdUsers);

const getUserBankAccounts = async ({ queryKey }: any): Promise<any> => {
  const [_key, { email }] = queryKey;
  const { data } = await axios.get(
    `https://comms.globalxchange.com/coin/user/bank/account/get?email=${email}`
  );
  return data?.bankAccounts_info;
};

export const useUserBankAccounts = (email: string) =>
  useQuery(["getUserBankAccounts", { email }], getUserBankAccounts);

const getAllBankList = async (): Promise<any> => {
  const { data } = await axios.get(
    `https://accountingtool.apimachine.com/getlist-of-institutes_By_Country/india`
  );
  return data?.data;
};

export const useAllBankList = () =>
  useQuery(["getAllBankList"], getAllBankList);

const getAllVaultBalance = async ({ queryKey }: any): Promise<any> => {
  const [_key, { email, withBalance, type }] = queryKey;
  const { data } = await axios.post(
    "https://comms.globalxchange.com/coin/vault/service/coins/get",
    {
      app_code: APP_CODE,
      email: email,
      displayCurrency: DISP_CURRENCY,
      post_app_prices: true,
      with_balances: withBalance,
      orderby_dsc: true,
      getAllCoins: true,
      type,
    }
  );
  return data;
};

export const useAllVaultBalance = (
  email?: string,
  type?: string,
  withBalance?: boolean
) => {
  const query = useQuery(
    ["getAllVaultBalance", { email, withBalance, type }],
    getAllVaultBalance
  );
  return query;
};

const getAllFundTypes = async (): Promise<any> => {
  const { data } = await axios.get(
    `https://comms.globalxchange.com/coin/investment/sub/types/get?parent_investment_type=funds&getStats=true&displayCurrency=${DISP_CURRENCY}`
  );
  return data?.investmentSubTypes;
};

export const useAllFundTypes = () =>
  useQuery(["getAllFundTypes"], getAllFundTypes);

const getAllFundSubTypes = async ({ queryKey }: any): Promise<any> => {
  const [_key, type] = queryKey;
  const { data } = await axios.get(
    `https://comms.globalxchange.com/coin/investment/path/get?investmentSubType=${type}`
  );
  return data?.paths;
};

export const useAllFundSubTypes = (type?: string) =>
  useQuery(["getAllFundSubTypes", type], getAllFundSubTypes);

const getTokenDetailByPath = async ({ queryKey }: any): Promise<any> => {
  const [_key, pathId] = queryKey;
  const { data } = await axios.get(
    `https://comms.globalxchange.com/coin/investment/path/get?path_id=${pathId}`
  );
  return data?.paths?.[0];
};

export const useTokenDetailByPath = (pathId?: string) =>
  useQuery(["getTokenDetailByPath", pathId], getTokenDetailByPath);

async function getMarketCap({ queryKey }: any) {
  const [_, { email, vaultset }] = queryKey;
  let resp;
  if (email && vaultset) {
    resp = await axios.get(
      `https://api.terminals.dev/vault-set/vaultSetStats`,
      {
        params: {
          email,
          vaultset,
        },
      }
    );
  } else {
    throw new Error("Path & Email Not Got");
  }
  return resp.data;
}

export const useMarketCapByPath = (pathId?: string) =>
  useQuery(["getMarketCap", pathId], getMarketCap);

async function getChartDataByPath({ queryKey }: any) {
  const [_, { email, vaultset }] = queryKey;
  if (email && vaultset) {
    const resp = await axios.get(
      `https://api.terminals.dev/vault-set/vaultSetStats/historical?email=${email}&vaultSet=${vaultset}`
    );
    return resp.data;
  }
  return;
}

export const useChartDataByPath = (pathId?: string, bankerMail?: string) =>
  useQuery(
    ["getChartDataByPath", { email: bankerMail, vaultset: pathId }],
    getChartDataByPath
  );

async function getCoinList() {
  const { data } = await axios.get(
    `https://comms.globalxchange.com/coin/vault/get/all/coins`
  );
  return data?.coins;
}

export const useCoinList = () => useQuery(["getCoinList"], getCoinList);

async function getCoinAdress({ queryKey }: any) {
  const [_, { email, coin }] = queryKey;
  const { data } = await axios.post(
    "https://comms.globalxchange.com/coin/vault/service/coins/get",
    {
      app_code: APP_CODE,
      email,
      include_coins: [coin],
    }
  );
  return data?.coins_data?.[0];
}
export const useCoinAdress = (email: string, coin: string) =>
  useQuery(["getCoinAdress", { email, coin }], getCoinAdress);
