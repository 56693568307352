import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { useParams } from "react-router-dom";
import { useMarketCapByPath, useTokenDetailByPath } from "../../queryHooks";
import { FormatCurrency } from "../../utils/FunctionTools";
import StaticsCard from "./StaticsCard";
import classNames from "./virtualProspectusAnalysis.module.scss";
import VPCandleChart from "./VPCandleChart";

function VirtualProspectusAnalysis() {
  const { pathId } = useParams();
  const { data: tokenSelected } = useTokenDetailByPath(pathId);
  const { data, isLoading } = useMarketCapByPath(pathId);
  const [isUsd, setIsUsd] = useState(true);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [chartType, setChartType] = useState("Market Cap");
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [typeModal, setTypeModal] = useState(false);
  const [cards, setCards] = useState<any[]>([]);
  let coinsObject: any = {};

  const next = useCallback((index) => {
    if (index !== 0) {
      setCards((cards) => {
        const [first, ...rest] = cards;
        return [...rest, first];
      });
      setTimeout(() => {
        next(index - 1);
      }, 500);
    }
  }, []);

  useEffect(() => {
    setCards([
      {
        key: "1",
        title: "All Time Stats",
        date: `${moment(tokenSelected?.timestamp).format(
          "MMMM Do YYYY [at] h:mm A z"
        )} - Right Now`,
      },
      {
        key: "2",
        title: "Past Seven Days",
        date: `${moment()
          .subtract(7, "d")
          .format("MMMM Do YYYY [at] h:mm A z")} - Right Now`,
      },
      {
        key: "3",
        title: "Past Thirty Days",
        date: `${moment()
          .subtract(30, "d")
          .format("MMMM Do YYYY [at] h:mm A z")} - Right Now`,
      },
      {
        key: "4",
        title: "Past 90 Days",
        date: `${moment()
          .subtract(90, "d")
          .format("MMMM Do YYYY [at] h:mm A z")} - Right Now`,
      },
      {
        key: "5",
        title: "Past One Year",
        date: `${moment()
          .subtract(1, "year")
          .format("MMMM Do YYYY [at] h:mm A z")} - Right Now`,
      },
    ]);
  }, [tokenSelected]);
  return (
    <div className={classNames.vPAnalysisContent}>
      <div className={classNames.coinContent}>
        <div className={classNames.coinDetail}>
          <div className={classNames.coinHead}>
            <img src={tokenSelected?.token_profile_data?.coinImage} alt="" />
            <span className={classNames.coinName}>
              {tokenSelected?.token_profile_data?.coinName}
            </span>
            <span className={classNames.coinValue}>
              {FormatCurrency(tokenSelected?.token_price, tokenSelected?.asset)}
              <div className={classNames.coinBtn} onClick={() => {}}>
                <img src={tokenSelected?.asset_metaData?.coinImage} alt="" />
                {tokenSelected?.asset}
              </div>
            </span>
          </div>
          <p className={classNames.desc}>
            {tokenSelected?.token_profile_data?.coinDescription}
          </p>
        </div>
        {isLoading ? (
          <div className={classNames.vpCandleHead}>
            <div className={classNames.coinView}>
              <div className={classNames.rate}>
                <Skeleton width={120} />
              </div>
              <div className={classNames.coin}>
                <Skeleton width={40} height={40} />
              </div>
            </div>
            <div className={classNames.marketView}>
              <Skeleton width={200} />
            </div>
            <div className={classNames.coinView}>
              <div className={classNames.rate}>
                <Skeleton width={120} />
              </div>
              <div className={classNames.coin}>
                <Skeleton width={40} height={40} />
              </div>
            </div>
          </div>
        ) : (
          <div className={classNames.vpCandleHead}>
            <div
              className={`${classNames.coinView} ${
                classNames[isUsd.toString()]
              }`}
              onClick={() => setIsUsd(true)}
            >
              <div className={classNames.rate}>
                ${FormatCurrency(data?.holdingsAgainstUSD?.date2?.total, "USD")}
              </div>
              <div className={classNames.coin}>
                <img src={coinsObject?.USD?.coinImage} alt="" />
              </div>
            </div>
            <div
              className={classNames.marketView}
              onClick={() => setTypeModal(true)}
            >
              {chartType}
              <div className={classNames.against}>
                Against {isUsd ? "USD" : data?.displayCurrency}
              </div>
            </div>
            <div
              className={`${classNames.coinView} ${
                classNames[(!isUsd).toString()]
              }`}
              onClick={() => setIsUsd(false)}
            >
              <div className={classNames.rate}>
                $
                {FormatCurrency(
                  data?.holdingsAgainstDisplayCurrency?.date2?.total,
                  data?.displayCurrency
                )}
              </div>
              <div className={classNames.coin}>
                <img
                  src={
                    coinsObject && coinsObject[data?.displayCurrency]?.coinImage
                  }
                  alt=""
                />
              </div>
            </div>
          </div>
        )}
        <VPCandleChart isUsd={isUsd} tokenSelected={tokenSelected} />
      </div>
      <div className={classNames.statsContent}>
        <div className={classNames.selectedStats}>
          <div className={classNames.title}>{cards[0]?.title}</div>
          <div className={classNames.date}>{cards[0]?.date}</div>
        </div>
        <div className={classNames.statsContainer}>
          {cards.map((card, i) => (
            <StaticsCard
              key={card.key}
              card={card}
              i={i}
              next={next}
              setModalOutSpace={() => {}}
              tokenSelected={tokenSelected}
            />
          ))}
        </div>
      </div>
    </div>
  );
}

export default VirtualProspectusAnalysis;
