import { memo } from "react";
import classNames from "./topNavbarAppsSubMenu.module.scss";
import loginIcon from "../../static/images/sidebarIcons/login.svg";
import registerIcon from "../../static/images/sidebarIcons/register.svg";
import appleIcon from "../../static/images/sidebarIcons/apple.svg";
import gPlayIcon from "../../static/images/sidebarIcons/gPlay.svg";
import affiliateIcon from "../../static/images/sidebarIcons/affiliate.svg";
import bharatTrustIcon from "../../static/images/appIcons/bharatTrust.svg";
import indiaCoinsIcon from "../../static/images/appIcons/indiaCoins.svg";
import indianInvestorIcon from "../../static/images/appIcons/indianInvestor.svg";
import indianMarketIcon from "../../static/images/appIcons/indianMarket.svg";
import indianOtcIcon from "../../static/images/appIcons/indianOtc.svg";
import taxChainsIcon from "../../static/images/appIcons/taxChains.svg";
import { useDispatch, useSelector } from "react-redux";
import { selectLoginData, logout } from "../../app/loginSlice";
import { Link } from "react-router-dom";

const APPS_LIST = [
  {
    name: "IndianOTC",
    desc: "Digital Asset Trading Hub",
    icon: indianOtcIcon,
    link: "",
  },
  {
    name: "IndianInvestor",
    desc: "Wealth Management System",
    icon: indianInvestorIcon,
    link: "https://www.indianinvestor.com",
  },
  {
    name: "TaxChains",
    desc: "Crypto Tax Software",
    icon: taxChainsIcon,
    link: "https://www.taxchains.com",
  },
  {
    name: "IndiaCoins",
    desc: "India’s NFT Marketplace",
    icon: indiaCoinsIcon,
    link: "https://www.indiacoins.com",
  },
  {
    name: "BharatTrust",
    desc: "Social Investing Platform",
    icon: bharatTrustIcon,
    link: "https://www.bharattrust.com",
  },
  {
    name: "IndianMarket",
    desc: "Financial Media & News",
    icon: indianMarketIcon,
    link: "https://www.indian.market",
  },
];

function TopNavbarAppsSubMenu({
  openTab,
  setOpenTab,
  setLoginModalOpen,
}: {
  openTab: boolean;
  setOpenTab: (param: string) => void;
  setLoginModalOpen: (param: boolean) => void;
}) {
  const dispatch = useDispatch();
  const { email } = useSelector(selectLoginData);
  return (
    <div
      className={`${classNames.topNavbarAppsSubMenuWrap} ${
        classNames[openTab.toString()]
      }`}
    >
      {openTab && (
        <div className={classNames.overlay} onClick={() => setOpenTab("")} />
      )}
      <div className={classNames.topNavbarAppsSubMenu} style={{marginTop: '160px'}}>
        <div className={classNames.subMenuList}>
          <div
            className={classNames.sideMenu}
            onClick={() => {
              if (email) {
                dispatch(logout());
              } else {
                setOpenTab("");
                setLoginModalOpen(true);
              }
            }}
          >
            <img src={loginIcon} alt="" />
            <span>{email ? "Logout" : "Login"}</span>
          </div>
          {email ? (
            <div className={classNames.sideMenu}>
              <img src={affiliateIcon} alt="" />
              <span>Affiliates</span>
            </div>
          ) : (
            <Link to="/getstarted" className={classNames.sideMenu}>
              <img src={registerIcon} alt="" />
              <span>Register</span>
            </Link>
          )}
          <div className={classNames.sideMenu}>
            <img src={appleIcon} alt="" />
            <span>For Iphone</span>
          </div>
          <div className={classNames.sideMenu}>
            <img src={gPlayIcon} alt="" />
            <span>For Android</span>
          </div>
        </div>
        <div className={classNames.tabDetailContent}>
          <div className={classNames.titlle}>
            One Account. Mutiple Advantages
          </div>
          <div className={classNames.subtitle}>
            With An IndianOTC Account You Also Get Access To The INR Group Apps
          </div>
          <div className={classNames.actionList}>
            {APPS_LIST.map((app, i) => (
              <a
                href={app.link}
                target="_blank"
                key={i}
                className={classNames.action}
                rel="noreferrer"
              >
                <img src={app.icon} alt="" className={classNames.icon} />
                <div className={classNames.detail}>
                  <div className={classNames.actName}>{app.name}</div>
                  <div className={classNames.actDesc}>{app.desc}</div>
                </div>
              </a>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default memo(TopNavbarAppsSubMenu);
