import { Link } from "react-router-dom";
import classNames from "./fundsMarketTypesPage.module.scss";
import MainLayout from "../../layouts/MainLayout";
import funds from "../../static/images/assetLogos/funds.svg";
import { useAllFundTypes } from "../../queryHooks";
import { FormatNumber } from "../../utils/FunctionTools";
import { ReactComponent as SearchIcon } from "../../static/images/clipIcons/searchIcon.svg";
import { useState } from "react";

function FundsMarketTypesPage(): JSX.Element {
  const [search, setSearch] = useState("");
  const { data = [] } = useAllFundTypes();
  return (
    <MainLayout
      className={classNames.fundsMarketTypesPage}
      searchComponent={
        <label className={classNames.searchWrap}>
          <SearchIcon className={classNames.icon} />
          <input
            type="text"
            placeholder="Search For Fund Types..."
            className={classNames.search}
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        </label>
      }
    >
      <>
        <div className={classNames.topLogoWrap}>
          <img src={funds} alt="" className={classNames.logo} />
        </div>
        <div className={classNames.cardList}>
          {data
            ?.filter((type: any) =>
              type.name.toLowerCase().includes(search.toLowerCase())
            )
            ?.map((type: any) => (
              <Link to={type.code} className={classNames.card} key={type._id}>
                <div
                  className={classNames.header}
                  style={{ backgroundColor: `#${type.color_codes?.[0]}` }}
                >
                  <div className={classNames.cardLogo}>
                    <img src={type.icon} alt="" />
                  </div>
                  <div className={classNames.btnInvest}>Invest</div>
                </div>
                <div className={classNames.content}>
                  <div className={classNames.cardTitle}>
                    <span>{type.name}</span>
                    <span>
                      {FormatNumber(type.pathsCount, 0)} Fund
                      {type.pathsCount > 1 && "s"}
                    </span>
                  </div>
                  <p className={classNames.cardDesc}>{type.description}</p>
                  <div className={classNames.values}>
                    <div className={classNames.itm}>
                      <div className={classNames.label}>All Time Returns</div>
                      <div className={`${classNames.value} ${classNames.true}`}>
                        35.00%
                      </div>
                    </div>
                    <div className={classNames.itm}>
                      <div className={classNames.label}>Investors</div>
                      <div className={`${classNames.value}`}>
                        {FormatNumber(type.totalTokenHolders, 0)}
                      </div>
                    </div>
                    <div className={classNames.itm}>
                      <div className={classNames.label}>Market Cap</div>
                      <div className={`${classNames.value}`}>
                        {FormatNumber(type.total_holdings_in_dc / 1000, 0)}K
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            ))}
          {Array(4)
            .fill("")
            .map((_, i) => (
              <div className={classNames.card} key={i}>
                <span className={classNames.cs}>Coming Soon</span>
              </div>
            ))}
        </div>
      </>
    </MainLayout>
  );
}

export default FundsMarketTypesPage;
