import { useState } from "react";
import classNames from "./founderPage.module.scss";
import MainLayout from "../../layouts/MainLayout";
import founderRohit from "../../static/images/founders/founderRohit.png";
import founderRohitMobile from "../../static/images/founders/founderRohitMobile.jpeg";
import founderBg from "../../static/images/founders/founderBg.jpeg";
import FounderMoreCarousel from "../../components/FounderMoreCarousel";
import founderShorupan from "../../static/images/backgrounds/shorupan.webp";
import founderKarthik from "../../static/images/backgrounds/karthik.jpeg";
import { useParams } from "react-router-dom";

const founderData: any = {
  rohith: {
    img: founderRohit,
    imgMobile: founderRohitMobile,
    name: "Rohith G",
    post: "Founder & CEO",
    desc: "Rohith Gullipali is one of India's most promising blockchain entrepreneurs and a sought-after thought leader within its burgeoning cryptocurrency space. After an initial career in Cap Gemini as a technology analyst, Rohith immersed himself in the then-nascent Indian cryptocurrency market in 2017. With a knack for structured finance and a poignant understanding of the underlying technology, Rohith jumped right into launching a cryptocurrency fund. After experiencing unexpected success in the fund management business, Rohit saw an even more significant opportunity. The Indian cryptocurrency markets were going retail, and investors from all walks of life took active positions in volatile assets that they didn't understand. Rohit knew that ordinary people needed exposure to this great asset class, but they were sure to lose money without the proper guidance. It was out of this problem that IndianOTC was born. He was going to build an easy-to-use cryptocurrency exchange, but this exchange would focus on helping users find and connect to experienced fund managers who would be able to operate their fund management business directly on IndianOTC. This allowed new investors to take advantage of the best crypt minds in India without having to make the onerous and risky decisions by themselves. Click here to see how IndianOTC and Rohit plan to bridge this gap for the next 100 Million Indians.....",
  },
  shorupan: {
    img: founderShorupan,
    imgMobile: founderRohitMobile,
    name: "Shorupan P",
    post: "Co-Founder",
    desc: "Shorupan is a sophisticated blockchain investor and a sought after subject matter specialist for enterprise-level blockchain integration. With a background in structured portfolio management, Shorupan has consulted and scaled traditional financial firms whose specializations range from the foreign exchange markets to insurance derivatives. \nHis exposure to the cryptocurrency markets came during his tenure as Chief Investment Officer at Toronto based VC firm, Generation Z International. There he constructed one of Canada's first blockchain investment funds which monetized off the market boom of 2016-2017. Since then Shorupan has become a thought leader in the space, engaging with corporations and universities to help elucidate the business implications of the blockchain.\nIn 2018 he Co-Founded Nvest Global Enterprises and launched Canada's first retail cryptocurrency financial institution. Now he is scaling NGE to become a global provider of blockchain based banking systems and manages operations in over six countries.",
  },
  karthik: {
    img: founderKarthik,
    imgMobile: founderRohitMobile,
    name: "Karthik S",
    post: "Co-Founder",
    desc: "Shorupan is a sophisticated blockchain investor and a sought after subject matter specialist for enterprise-level blockchain integration. With a background in structured portfolio management, Shorupan has consulted and scaled traditional financial firms whose specializations range from the foreign exchange markets to insurance derivatives. \nHis exposure to the cryptocurrency markets came during his tenure as Chief Investment Officer at Toronto based VC firm, Generation Z International. There he constructed one of Canada's first blockchain investment funds which monetized off the market boom of 2016-2017. Since then Shorupan has become a thought leader in the space, engaging with corporations and universities to help elucidate the business implications of the blockchain.\nIn 2018 he Co-Founded Nvest Global Enterprises and launched Canada's first retail cryptocurrency financial institution. Now he is scaling NGE to become a global provider of blockchain based banking systems and manages operations in over six countries.",
  },
};

function FounderPage(): JSX.Element {
  const { founder } = useParams();
  const [readMore, setReadMore] = useState(false);
  return (
    <MainLayout className={classNames.founderPage}>
      <div className={classNames.pageWrap}>
        <div className={classNames.founderImageDetails}>
          <div className={classNames.founderImg}>
            <img src={founderBg} alt="" className={classNames.bgWrap} />
            <img
              src={founderData?.[founder || "rohith"]?.imgMobile}
              alt=""
              className={classNames.dpMobile}
            />
          </div>
          <div className={classNames.name}>
            {founderData?.[founder || "rohith"]?.name}
          </div>
          <div className={classNames.post}>
            {founderData?.[founder || "rohith"]?.post}
          </div>
          <img
            src={founderData?.[founder || "rohith"]?.img}
            alt=""
            className={classNames.bgImg}
          />
        </div>
        <div
          className={`${classNames.pageContent} ${
            classNames[readMore.toString()]
          }`}
        >
          <div className={classNames.title}>
            Who Is {founderData?.[founder || "rohith"]?.name}?
          </div>
          <p className={classNames.desc}>
            <NewlineText text={founderData?.[founder || "rohith"]?.desc} />
          </p>
          <span
            className={classNames.readMore}
            onClick={() => setReadMore(!readMore)}
          >
            {readMore ? "Read Less" : "Read More"}
          </span>
          <hr className={classNames.hr} />
          <div className={classNames.title}>Learn More About Me</div>
          <FounderMoreCarousel readMore={readMore} />
        </div>
      </div>
    </MainLayout>
  );
}

function NewlineText({ text }: { text: string }) {
  return (
    <>
      {text.split("\n").map((str) => (
        <p>{str}</p>
      ))}
    </>
  );
}

export default FounderPage;
