import { Fragment, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import classNames from "./mainLayout.module.scss";
import TopNavbar from "../../components/TopNavbar";
import { useOTCDetail } from "../../queryHooks";
import { useSelector } from "react-redux";
import { selectLoginData } from "../../app/loginSlice";
import { Navigate } from "react-router-dom";
import Carer from '../../components/TopNavbar/commonNav'

function MainLayout({
  className,
  children,
  searchComponent,
  loggedIn,
}: {
  className: string;
  children: JSX.Element;
  searchComponent?: JSX.Element;
  loggedIn?: boolean;
}): JSX.Element {
  const { email } = useSelector(selectLoginData);
  const { data } = useOTCDetail();
  useEffect(() => {
    document.body.style.setProperty("--app-color", `#${data?.color_code}`);
    document.body.style.setProperty(
      "--primary-color",
      `#${data?.other_data?.secondarycolorcode}`
    );
  }, [data]);

  if (loggedIn && !email) {
    return <Navigate to="/home" />;
  }

  return (
    <Fragment>
      <Helmet>
        <title>{data?.website_title}</title>
        <meta name="description" content={data?.website_description} />
      </Helmet>
      <div className={`${className} ${classNames.mainLayout}`}>
        <Carer />
        <TopNavbar searchComponent={searchComponent} />
        {children}
      </div>
    </Fragment>
  );
}

export default MainLayout;
