import React, { memo, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import useWebSocket from "react-use-websocket";
import { RootState } from "../../app/store";
import { FormatCurrency } from "../../utils/FunctionTools";
import classNames from "./terminalsOrderBook.module.scss";

const TerminalsOrderBook: React.FC<{}> = () => {
  const terminalsData = useSelector((state: RootState) => state.terminalsData);
  const { lastJsonMessage: orderBookData } = useWebSocket(
    `wss://stream.binance.com:9443/ws/${terminalsData?.pair
      .replace("/", "")
      .toLowerCase()}@depth5@1000ms`
  );
  const [largest, setLargest] = useState(0);

  useEffect(() => {
    let biggest = 0;
    orderBookData?.bids?.forEach((bid: any) => {
      if (bid[0] * bid[1] > biggest) {
        biggest = bid[0] * bid[1];
      }
    });
    orderBookData?.asks?.forEach((ask: any) => {
      if (ask[0] * ask[1] > biggest) {
        biggest = ask[0] * ask[1];
      }
    });
    setLargest(biggest);
  }, [orderBookData]);

  return (
    <div className={classNames.orderBookWrap}>
      <div className={classNames.orderBookHead}>
        <span>Price</span>
        <span>Amount</span>
        <span>Total</span>
      </div>
      <div className={classNames.stepsRed}>
        {orderBookData?.bids?.map((bid: any) => (
          <OrderItem
            key={JSON.stringify(bid)}
            value={bid}
            largest={largest}
            color="rgba(239,83,80,0.17)"
          />
        ))}
      </div>
      <div className={classNames.centerValue}>
        {FormatCurrency(terminalsData?.pairPrice, terminalsData?.quoteCoin)}
      </div>
      <div className={classNames.stepsGreen}>
        {orderBookData?.asks?.map((ask: any) => (
          <OrderItem
            key={JSON.stringify(ask)}
            value={ask}
            largest={largest}
            color="rgba(210,239,225,1)"
          />
        ))}
      </div>
    </div>
  );
};

function OrderItem({
  value,
  largest,
  color,
}: {
  value: number[];
  largest: number;
  color: string;
}) {
  const percentage = ((value[0] * value[1]) / (largest * 1.2)) * 100;
  return (
    <div
      className={classNames.step}
      style={{
        background: `linear-gradient(90deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0) ${
          100 - percentage
        }%, ${color} ${100 - percentage}%, ${color} 100%)`,
      }}
    >
      <span>{FormatCurrency(value[0], "USD")}</span>
      <span>{FormatCurrency(value[1], "BTC")}</span>
      <span>{FormatCurrency(value[0] * value[1], "USD")}</span>
    </div>
  );
}

export default memo(TerminalsOrderBook);
