import {createContext, useState } from 'react';

export const MainContext = createContext();

const MainContextProvider = ({ children }) => {
    const [selectnav, setselectnav] = useState(false)

    
    const value = {
        selectnav,
        setselectnav,
    }
    return (
        <MainContext.Provider value={value}>
            {children}
        </MainContext.Provider>
    );
}

export default MainContextProvider;

export const MainContextConsumer = MainContext.Consumer