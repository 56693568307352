import { useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import classNames from "./homePage.module.scss";
import MainLayout from "../../layouts/MainLayout";
import flagCoins from "../../static/images/backgrounds/flagCoins.webp";
import mockUp from "../../static/images/backgrounds/mockUp.webp";
import karthik from "../../static/images/backgrounds/karthik.jpeg";
import rohith from "../../static/images/backgrounds/rohith.webp";
import shorupan from "../../static/images/backgrounds/shorupan.webp";
import airDrops from "../../static/images/whyLogos/airDrops.svg";
import futures from "../../static/images/whyLogos/futures.svg";
import checkout from "../../static/images/whyLogos/checkout.svg";
import ifos from "../../static/images/whyLogos/ifos.svg";
import marketWatchers from "../../static/images/whyLogos/marketWatchers.svg";
import tellers from "../../static/images/whyLogos/tellers.svg";
import terminal from "../../static/images/whyLogos/terminal.svg";
import tokenOptions from "../../static/images/whyLogos/tokenOptions.svg";
import LoginModal from "../../components/LoginModal";
import { isVisible } from "../../utils/FunctionTools";
import HomeFooterMenus from "../../components/HomeFooterMenus";
import HomeStickyFooter from "../../components/HomeStickyFooter";

const SUB_APPS = [
  {
    name: "MarketWatchers",
    img: marketWatchers,
    desc: "IndianOTC enables Indians to access the cryptocurrency markets in a easy and safe way. You can fund their accounts and start trading in minutes. ",
    color: "#2EB9FF",
  },
  {
    name: "Funds",
    img: ifos,
    desc: "IndianOTC enables Indians to access the cryptocurrency markets in a easy and safe way. You can fund their accounts and start trading in minutes. ",
    color: "#186AB3",
  },
  {
    name: "Tellers",
    img: tellers,
    desc: "IndianOTC enables Indians to access the cryptocurrency markets in a easy and safe way. You can fund their accounts and start trading in minutes. ",
    color: "#262626",
  },
  {
    name: "Futures",
    img: futures,
    desc: "IndianOTC enables Indians to access the cryptocurrency markets in a easy and safe way. You can fund their accounts and start trading in minutes. ",
    color: "#383C41",
  },
  {
    name: "Airdrops",
    img: airDrops,
    desc: "IndianOTC enables Indians to access the cryptocurrency markets in a easy and safe way. You can fund their accounts and start trading in minutes. ",
    color: "#49B5AE",
  },
  {
    name: "Checkout",
    img: checkout,
    desc: "IndianOTC enables Indians to access the cryptocurrency markets in a easy and safe way. You can fund their accounts and start trading in minutes. ",
    color: "#6741A3",
  },
  {
    name: "Terminal",
    img: terminal,
    desc: "IndianOTC enables Indians to access the cryptocurrency markets in a easy and safe way. You can fund their accounts and start trading in minutes. ",
    color: "#292929",
  },
  {
    name: "TokenOptions",
    img: tokenOptions,
    desc: "IndianOTC enables Indians to access the cryptocurrency markets in a easy and safe way. You can fund their accounts and start trading in minutes. ",
    color: "#292934",
  },
];

function HomePage(): JSX.Element {
  const navigate = useNavigate();
  const refScroll = useRef<any>();
  const refIn = useRef<any>();
  const [loginModalOpen, setLoginModalOpen] = useState(false);
  const [isVisibleState, setIsVisibleState] = useState(false);
  console.log("isVisibleState", isVisibleState);
  return (
    <MainLayout className={classNames.homePage}>
      <>
        <div
          className={classNames.pageWrap}
          ref={refScroll}
          onScroll={() => {
            setIsVisibleState(isVisible(refIn?.current, refScroll?.current));
          }}
        >
          <section className={classNames.heroSection}>
            <div className={classNames.logoArea}>
              <div className={classNames.subTitle}>Introducing IndianOTC</div>
              <div className={classNames.title}>
                India’s Crypto <span>Gateway</span>
              </div>
              <p className={classNames.desc}>
                IndianOTC enables Indians to access the cryptocurrency markets
                in a easy and safe way. You can fund their accounts and start
                trading in minutes. Best of all, we allow our users to find and
                copy the best traders in the industry.
              </p>
              <div className={classNames.btns}>
                <div
                  className={classNames.btnReg}
                  onClick={() => setLoginModalOpen(true)}
                >
                  Login
                </div>
                <Link to="/getstarted" className={classNames.btnLearn}>
                  Get Started
                </Link>
              </div>
            </div>
            <img src={flagCoins} alt="" className={classNames.indMap} />
          </section>
          <section className={classNames.aboutMock}>
            <img src={mockUp} alt="" className={classNames.mockBg} />
            <div className={classNames.textAbout}>
              <div className={classNames.titleAbout}>
                About
                <span> Us</span>
              </div>
              <p className={classNames.aboutDesc}>
                IndianOTC is a platform that enables Indians to access the
                cryptocurrency markets in a easy and safe way. Our exchange
                platform allows users to fund their accounts and start trading
                in minutes. Best of all, we allow our users to find and copy the
                best traders in the industry.
              </p>
              <div className={classNames.btns}>
                <Link to="/getstarted" className={classNames.btnReg}>
                  Get Started
                </Link>
              </div>
            </div>
          </section>
          <section className={classNames.founders}>
            <div className={classNames.titleFounders}>
              The
              <span>Founders</span>
            </div>
            <div className={classNames.foundersList}>
              <Link to="/founders/rohith" className={classNames.founderCard}>
                <img src={rohith} alt="" className={classNames.founderImg} />
                <div className={classNames.founderDetail}>
                  <div className={classNames.founderName}>Rohith G</div>
                  <div className={classNames.founderPost}>View Profile</div>
                </div>
              </Link>
              <Link to="/founders/karthik" className={classNames.founderCard}>
                <img src={karthik} alt="" className={classNames.founderImg} />
                <div className={classNames.founderDetail}>
                  <div className={classNames.founderName}>Karthik S</div>
                  <div className={classNames.founderPost}>View Profile</div>
                </div>
              </Link>
              <Link to="/founders/shorupan" className={classNames.founderCard}>
                <img src={shorupan} alt="" className={classNames.founderImg} />
                <div className={classNames.founderDetail}>
                  <div className={classNames.founderName}>Shorupan P</div>
                  <div className={classNames.founderPost}>View Profile</div>
                </div>
              </Link>
            </div>
          </section>
          <section className={classNames.whyIndianOtc}>
            <div className={classNames.titleWhy}>
              Why Indian
              <span>OTC</span>
            </div>
            <div className={classNames.reasonList}>
              {SUB_APPS.map((app, i) => (
                <div className={classNames.reasonCard} key={app.name}>
                  <div
                    className={classNames.bar}
                    style={{ backgroundColor: app.color }}
                  />
                  <div className={classNames.header}>
                    <img src={app.img} alt={app.name} />
                    <span>{app.name}</span>
                  </div>
                  <div className={classNames.desc}>{app.desc}</div>
                  <div className={classNames.expanded}>
                    <div
                      className={classNames.bar}
                      style={{ backgroundColor: app.color }}
                    />
                    <div className={classNames.head}>
                      <img src={app.img} alt={app.name} />
                      <span>{app.name}</span>
                    </div>
                    <div className={classNames.desc}>{app.desc}</div>
                  </div>
                </div>
              ))}
            </div>
          </section>
          <HomeFooterMenus refIn={refIn} />
          {!isVisibleState && <HomeStickyFooter />}
        </div>
        {loginModalOpen && (
          <LoginModal
            onClose={() => setLoginModalOpen(false)}
            onSuccess={() => {
              setLoginModalOpen(false);
              navigate("/trading/vaults");
            }}
          />
        )}
      </>
    </MainLayout>
  );
}

export default HomePage;
