import { Link } from "react-router-dom";
import { FULL_LOGO } from "../../configs";
import PasteTextInput from "../PasteTextInput";
import classNames from "./getStartedPrereg.module.scss";
function GetStartedPrereg() {
  return (
    <>
      <div className={classNames.textSide}>
        <div className={classNames.title}>
          That’s Great. Your Already 50% Done
        </div>
      </div>
      <div className={classNames.contentCards}>
        <img src={FULL_LOGO} alt="" className={classNames.logo} />
        <div className={classNames.desc}>
          Enter The Temporary Credentials You Reiceved
        </div>
        <PasteTextInput placeholder="Email" />
        <PasteTextInput placeholder="Temp Password" type="password" />
        <div className={classNames.btns}>
          <Link to="/getstarted" className={classNames.btnGoBack}>
            Go Back
          </Link>
          <div className={classNames.btnProceed}>Proceed</div>
        </div>
      </div>
    </>
  );
}

export default GetStartedPrereg;
