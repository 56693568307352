import { useRef, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";
import AliceCarousel from "react-alice-carousel";
import classNames from "./aboutPage.module.scss";
import MainLayout from "../../layouts/MainLayout";
import '../../../node_modules/react-alice-carousel/lib/alice-carousel.css'
import aboutMainImage from "../../static/images/backgrounds/aboutMainImage.jpg";
import carouselsData from "./dummyData"
import greenVector from "../../static/images/sidebarIcons/about/greenVector.svg";
import arrow from "../../static/images/sidebarIcons/about/arrow.svg";
import brokersIcon from "../../static/images/sidebarIcons/about/brokers.svg";
import individualsIcon from "../../static/images/sidebarIcons/about/individuals.svg";
import wealthIcon from "../../static/images/sidebarIcons/about/wealth.svg";
import fundmanagerIcon from "../../static/images/sidebarIcons/about/fundmanager.svg";
import LoginModal from "../../components/LoginModal";
import { isVisible } from "../../utils/FunctionTools";
import axios from "axios";
import raindrops from "../../static/images/stickyFooterIcons/raindrops.svg";
import exchange from "../../static/images/stickyFooterIcons/exchange.svg";
import im from "../../static/images/stickyFooterIcons/im.svg";
import left from "../../static/images/stickyFooterIcons/left.svg";
import right from "../../static/images/stickyFooterIcons/right.svg";
import insta from '../../static/images/footerIcons/insta.svg';
import youtube from '../../static/images/footerIcons/youtube.svg';
import whatsapp from '../../static/images/footerIcons/whatsapp.svg';
import email from '../../static/images/footerIcons/email.svg';
import connect from '../../static/images/footerIcons/connect.svg';
import telegram from '../../static/images/footerIcons/telegram.svg';
import news from '../../static/images/footerIcons/news.svg';
import media from '../../static/images/footerIcons/media.svg';
import terms from '../../static/images/footerIcons/terms.svg';
import privacy from '../../static/images/footerIcons/privacy.svg';
import careers from '../../static/images/footerIcons/careers.svg';
import investors from '../../static/images/footerIcons/investors.svg';
import { servicesVersion } from "typescript";
import HomeStickyFooter from "../../components/HomeStickyFooter";

const SUB_CARDS1 = [
  {
    id: 0,
    name: "Individuals",
    img: individualsIcon,
    navpath: "/about/company",
    desc: "Buy and sell popular cryptocurrencies. Invest in Funds managed by expert traders.",
  },
  {
    id: 1,
    name: "Wealth",
    img: wealthIcon,
    navpath: "/about/wealth",
    desc: "Buy and store large amounts of digital assets with complete privacy.",
  },
];

const SUB_CARDS2 = [
  {
    id: 2,
    name: "Fund Managers",
    img: fundmanagerIcon,
    navpath: "/about/fundmanagers",
    desc: "Create, manage, and grow your asset management business with the IFO system.",
  },
  {
    id: 3,
    name: "Brokers",
    img: brokersIcon,
    navpath: "/about/brokers",
    desc: "Launch your brokerage business and get paid for helping people trade crypto.",
  },
  
]

const engageMenu = [
  {
    id:0,
    menuicon:insta,
    menuItem:'Instagram',
  },
  {
    id:1,
    menuicon:youtube,
    menuItem:'Youtube',
  },
  {
    id:2,
    menuicon:whatsapp,
    menuItem:'WhatsApp',
  },
  {
    id:3,
    menuicon:connect,
    menuItem:'Connection',
  },
  {
    id:4,
    menuicon:telegram,
    menuItem:'Telegram',
  },
  {
    id:5,
    menuicon:email,
    menuItem:'Email',
  },
]

const aboutIndianOtcMenu = [
  {
    id:0,
    menuicon:news,
    menuItem:'News',
  },
  {
    id:1,
    menuicon:media,
    menuItem:'Media Assets',
  },
  {
    id:2,
    menuicon:terms,
    menuItem:'Terms Of Use',
  },
  {
    id:3,
    menuicon:privacy,
    menuItem:'Privacy Policy',
  },
  {
    id:4,
    menuicon:careers,
    menuItem:'Careers',
  },
  {
    id:5,
    menuicon:investors,
    menuItem:'Investors',
  },
]

const collection1 = [
      {
        title:'Buy Bitcoin'
      },
      {
        title:'Buy Ethereum'
      },
      {
        title:'Buy USDT'
      },
      {
        title:'Buy Indra'
      },
      {
        title:'Buy Funds'
      },
      {
        title:'Buy Solana'
      },
      {
        title:'Buy Dogecoin'
      },
]
const collection2 = [
      {
        title:'Sell Bitcoin'
      },
      {
        title:'Sell Ethereum'
      },
      {
        title:'Sell USDT'
      },
      {
        title:'Sell Indra'
      },
      {
        title:'Sell Funds'
      },
      {
        title:'Sell Solana'
      },
      {
        title:'Sell Dogecoin'
      },
]

const collection3 = [
      {
        title:'Invest In FundCoins'
      },
      {
        title:'Collect RainDrops'
      },
      {
        title:'Create FundCoin'
      },
      {
        title:'List New Token'
      },
      {
        title:'Checkout With Crypto'
      },
      {
        title:'Join MarketWatchers'
      },
      {
        title:'IndianOTC Wealth'
      },
    ]


function AboutPage(): JSX.Element {
  const navigate = useNavigate();
  const refScroll = useRef<any>();
  const refIn = useRef<any>();
  const [loginModalOpen, setLoginModalOpen] = useState(false);
  const [isVisibleState, setIsVisibleState] = useState(false);
  const [coinData, setcoinData] = useState<any[]>([])
  const [carouselData, setcarouselData] = useState<any[]>([])

  const [rainDrop, setRainDrop] = useState(true);
  const [step, setStep] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setRainDrop(false);
      setInterval(() => {
        setStep((prev) => !prev);
      }, 3000);
    }, 2000);
  }, []);
    
  const getCoins = async () => {
      const data = await axios.get(
        "https://api.coingecko.com/api/v3/coins/markets?vs_currency=INR&order=gecko_desc&per_page=10&page=1&sparkline=false&price_change_percentage=24h",
      );
      // return data
      setcoinData(data.data)
    }
  const items =  coinData.map((carosuelCards,i) => {
    return (
      <div style={{
        margin: i===0 ? '10px 0 10px 0': '10px 0 10px 7px',
        padding:'1rem',
        cursor: "pointer",
        border: "1px solid #e7e7e7",
        borderRadius: "20px",
        width: '25rem',
        height:'10rem'
      }}
      className={classNames.cardhover}
      >
        <div style={{display:'flex'}}>
          <div  style={{display:'flex', flexDirection:'column'}}>
            <div style={{display:'flex'}}>
              <img src={carosuelCards.image} alt="" style={{height:'30px',width:'30px'}}/>
              <h2 style={{margin: '1px 0 3px 0'}}>&nbsp;{carosuelCards.name}</h2>
            </div>
            <div style={{display:'flex', marginTop:"5px",}}>
              <img src={greenVector} alt="" />
              <h6 style={{color:'green', marginTop:0, marginBottom:0}}>₹{carosuelCards.price_change_24h}</h6>
            </div>
            <p style={{fontSize:'13px',  marginTop:'49px'}}>Supply:  {carosuelCards.total_supply}</p>
          </div>
          <div style={{display:'flex', flexDirection:'column', marginLeft:'auto'}}>
            <h2 style={{marginLeft:'auto', marginTop:'1px', marginBottom:'3px'}}>₹{carosuelCards.current_price}</h2>
            <div style={{display:'flex', marginLeft:'auto', marginTop:"2px"}}>
              <img src={greenVector} alt="" />
              <h6 style={{color:'green', marginTop:0, marginBottom:0}}>{carosuelCards.price_change_percentage_24h}%</h6>
            </div>
            <p style={{fontSize:'13px', marginLeft:'auto', marginTop:'49px'}}>Market Cap: <span>{carosuelCards.market_cap}</span></p>
          </div>
        </div>
      </div>
  )})
  const responsive = {
    0: {
      items: 2
    },
    512: {
      items: 4
    }
  };
  console.log("isVisibleState", isVisibleState);
  useEffect(() => {
    document.body.style.overflow = "hidden";
    getCoins();
    setInterval(() => getCoins(), 60000)
  }, []);
  return (
    <MainLayout className={classNames.homePage}>
      <>
        <div
          className={classNames.pageWrap}
          ref={refScroll}
          onScroll={() => {
            setIsVisibleState(isVisible(refIn?.current, refScroll?.current));
          }}
        >
        <div className={classNames.allabovescticky}>
            <section
              className={classNames.heroSection}
              style={{ borderBottom: "1px solid #e7e7e7" }}
            >
              <div className={classNames.logoArea}>
                <div className={classNames.subTitle}>Introducing IndianOTC</div>
                <div className={classNames.title}>
                  India’s Crypto <span>Gateway</span>
                </div>
                <p className={classNames.desc}>
                  IndianOTC enables Indians to access the cryptocurrency markets
                  in a easy and safe way. You can fund their accounts and start
                  trading in minutes. Best of all, we allow our users to find and
                  copy the best traders in the industry.
                </p>
                <div className={classNames.btns}>
                  <div
                    className={classNames.btnReg}
                    onClick={() => window.open("https://app.indianotc.com/", "_blank")}
                  >
                    Login
                  </div>
                  <Link to="/getstarted" className={classNames.btnLearn}>
                    Get Started
                  </Link>
                </div>
              </div>
              {/* <img src={aboutMainImage} alt="" className={classNames.indMap} /> */}
              <motion.div style={{display:'flex', flexDirection:'column'}}
              initial={{ x: "30%", opacity: 0 }}
              animate={{ x: 0, opacity: 1 }}
              exit={{ x: "-30%", opacity: 0 }}
              transition={{ ease: [0.57, 0.67, 0.83, 0.67] }}>
                <div
                    className={classNames.cards}
                    style={{ display: "flex", justifyContent: "space-evenly" }}
                  >
                    {SUB_CARDS1.map((app, i) => (
                      <div
                        className={classNames.indvcards}
                        onClick={() => navigate(app.navpath)}
                        style={{
                          margin: '3% 0 0 0',
                          cursor: "pointer",
                          border: "1px solid #e7e7e7",
                          padding: "3%",
                          borderRadius: "20px",
                          width: "45%",
                          height:'90%'
                        }}
                      >
                        <h4
                          style={{
                            fontSize: "30px",
                            fontWeight: "bold",
                            marginBlockEnd: "0.8rem",
                            marginTop: 0
                          }}
                        >
                          {app.name}
                        </h4>
                        <p style={{ height: "25%", lineHeight: "2" }}>{app.desc}</p>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            paddingBottom: "3%",
                            marginTop: "2rem",
                          }}
                        >
                          <button className={classNames.zoom1}>
                            <span style={{ display: "flex" }}>
                              <h1 className={classNames.text1}>Check</h1>
                              <img
                                className={classNames.arrow1}
                                src={arrow}
                                alt=""
                              />
                            </span>
                          </button>
                          <img
                            key={i}
                            className={classNames.zoom}
                            src={app.img}
                            alt=""
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                  <div
                    className={classNames.cards}
                    style={{ display: "flex", justifyContent: "space-evenly" }}
                  >
                    {SUB_CARDS2.map((app, i) => (
                      <div
                        className={classNames.indvcards}
                        onClick={() => navigate(app.navpath)}
                        style={{
                          margin: '3% 0 0 0',
                          cursor: "pointer",
                          border: "1px solid #e7e7e7",
                          padding: "3%",
                          borderRadius: "20px",
                          width: "45%",
                          height:'85%',
                        }}
                      >
                        <h4
                          style={{
                            fontSize: "30px",
                            fontWeight: "bold",
                            marginBlockEnd: "0.8rem",
                            marginTop: 0
                          }}
                        >
                          {app.name}
                        </h4>
                        <p style={{ height: "25%", lineHeight: "2" }}>{app.desc}</p>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            paddingBottom: "3%",
                            marginTop: "2rem",
                          }}
                        >
                          <button className={classNames.zoom1}>
                            <span style={{ display: "flex" }}>
                              <h1 className={classNames.text1}>Check</h1>
                              <img
                                className={classNames.arrow1}
                                src={arrow}
                                alt=""
                              />
                            </span>
                          </button>
                          <img
                            key={i}
                            className={classNames.zoom}
                            src={app.img}
                            alt=""
                          />
                        </div>
                      </div>
                    ))}
                  </div>
              </motion.div>
              
            </section>
            <div className={classNames.totalscrollBody}>
            <div className={classNames.innerscroolbody}>
            <section
              className={classNames.mobileheroSection}
            >
              <div className={classNames.logoArea}>
                <div className={classNames.subTitle}>Introducing IndianOTC</div>
                <div className={classNames.title}>
                  India’s Crypto <span className={classNames.titlespan}>Gateway</span>
                </div>
                <div className={classNames.btns}>
                  <div
                    className={classNames.btnReg}
                    onClick={() => window.open("https://app.indianotc.com/", "_blank")}
                  >
                    Login
                  </div>
                  <Link to="/getstarted" className={classNames.btnLearn}>
                    Get Started
                  </Link>
                </div>
              </div>

              <motion.div style={{display:'flex', flexDirection:'column'}}
              initial={{ x: "30%", opacity: 0 }}
              animate={{ x: 0, opacity: 1 }}
              exit={{ x: "-30%", opacity: 0 }}
              transition={{ ease: [0.57, 0.67, 0.83, 0.67] }}>
                <div
                    className={classNames.cards}
                  >
                    {SUB_CARDS1.map((app, i) => (
                      <div style={{display:'flex', justifyContent: 'center', margin: '20px 0 0 0'}}>
                        <div
                          className={classNames.indvcards}
                          onClick={() => navigate(app.navpath)}
                          style={{
                            margin: '3% 0 0 0',
                            cursor: "pointer",
                            border: "1px solid #e7e7e7",
                            padding: "3%",
                            borderRadius: "20px",
                            width: "80%",
                            height:'80%',
                          }}
                        >
                          <h4
                            style={{
                              fontWeight: '800',
                              fontSize: '20px',
                              lineHeight: '24px',
                              marginBlockEnd: "0.8rem",
                              marginTop: 0
                            }}
                          >
                            {app.name}
                          </h4>
                          <p style={{ fontWeight: '400',
                              fontSize: '11px',
                              lineHeight: '22px', }}>{app.desc}</p>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "space-between",
                              paddingBottom: "3%",
                              marginTop: "2rem",
                            }}
                          >
                            <button className={classNames.zoom1}>
                              <span style={{ display: "flex" }}>
                                <h1 className={classNames.text1}>Check</h1>
                                <img
                                  className={classNames.arrow1}
                                  src={arrow}
                                  alt=""
                                />
                              </span>
                            </button>
                            <img
                              key={i}
                              className={classNames.zoom}
                              src={app.img}
                              alt=""
                            />
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                  <div
                    className={classNames.cards}
                    style={{ margin: '0 0 20px 0'}}
                  >
                    {SUB_CARDS2.map((app, i) => (
                      <div style={{display:'flex', justifyContent: 'center', margin: '20px 0'}}>
                        <div
                          className={classNames.indvcards}
                          onClick={() => navigate(app.navpath)}
                          style={{
                              margin: '3% 0 0 0',
                              cursor: "pointer",
                              border: "1px solid #e7e7e7",
                              padding: "3%",
                              borderRadius: "20px",
                              width: "80%",
                              height:'80%',
                          }}
                        >
                          <h4
                            style={{
                                fontWeight: '800',
                                fontSize: '20px',
                                lineHeight: '24px',
                                marginBlockEnd: "0.8rem",
                                marginTop: 0
                            }}
                          >
                            {app.name}
                          </h4>
                          <p style={{ fontWeight: '400',
                                fontSize: '11px',
                                lineHeight: '22px', }}>{app.desc}</p>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "space-between",
                              paddingBottom: "3%",
                              marginTop: "2rem",
                            }}
                          >
                            <button className={classNames.zoom1}>
                              <span style={{ display: "flex" }}>
                                <h1 className={classNames.text1}>Check</h1>
                                <img
                                  className={classNames.arrow1}
                                  src={arrow}
                                  alt=""
                                />
                              </span>
                            </button>
                            <img
                              key={i}
                              className={classNames.zoom}
                              src={app.img}
                              alt=""
                            />
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
              </motion.div>

              <div className={classNames.mobstickyFooter}>
              <AnimatePresence exitBeforeEnter>
                {rainDrop ? (
                  <motion.div className={classNames.footerWrap} key="0">
                    <img src={raindrops} alt="" className={classNames.raindrops} />
                  </motion.div>
                ) : step ? (
                  <motion.div
                    className={`${classNames.footerWrap} ${classNames.sb}`}
                    initial={{ x: "30%", opacity: 0 }}
                    animate={{ x: 0, opacity: 1 }}
                    exit={{ x: "-30%", opacity: 0 }}
                    transition={{ ease: [0.17, 0.67, 0.83, 0.67] }}
                    key="1"
                  >
                    <img src={left} alt="" className={classNames.left} />
                    <div className={classNames.banner}>
                      <img src={exchange} alt="" className={classNames.logo} />
                      <span>Own India’s Next Crypto Exchange</span>
                    </div>
                    <img src={right} alt="" className={classNames.right} />
                  </motion.div>
                ) : (
                  <motion.div
                    className={`${classNames.footerWrap} ${classNames.sb}`}
                    initial={{ x: "30%", opacity: 0 }}
                    animate={{ x: 0, opacity: 1 }}
                    exit={{ x: "-30%", opacity: 0 }}
                    transition={{ ease: [0.17, 0.67, 0.83, 0.67], duration: 0.5 }}
                    key="2"
                  >
                    <img src={left} alt="" className={classNames.left} />
                    <div className={classNames.banner}>
                      <img src={im} alt="" className={classNames.logo} />
                      <span>Come Meet Us At The Upcoming IndianMarket Expo</span>
                    </div>
                    <img src={right} alt="" className={classNames.right} />
                  </motion.div>
                )}
              </AnimatePresence>
              </div>
              
            </section>
            </div>
            <section className={classNames.mobfootersection}>
                  <div className={classNames.engagebox}>
                    <p className={classNames.text}>Engage With Us</p>
                    <div className={classNames.socialdata}>
                      {engageMenu.map((each, id) =>{
                        return(
                          <div className={classNames.eachmedia} key={id}>
                            <img className={classNames.mediaImg} src={each.menuicon} alt='' />
                            <p className={classNames.mediaName}>{each.menuItem}</p>
                          </div>
                        )
                      })}
                    </div>
                  </div>
                  <div className={classNames.engagebox}>
                    <p className={classNames.text}>About IndianOTC</p>
                    <div className={classNames.socialdata}>
                      {aboutIndianOtcMenu.map((each, id) =>{
                        return(
                          <div className={classNames.eachmedia} key={id}>
                            <img className={classNames.mediaImg} src={each.menuicon} alt='' />
                            <p className={classNames.mediaName}>{each.menuItem}</p>
                          </div>
                        )
                      })}
                    </div>
                  </div>
                  <div className={classNames.engagebox}>
                    <p className={classNames.text}>Popular Services</p>
                    <div className={classNames.servicedata}>
                      <div className={classNames.servicecollection}>
                        {collection1.map((each) =>{
                          return(
                            <div className={classNames.eachservice} key={each.title}>
                              <p className={classNames.serviceName}>{each.title}</p>
                            </div>
                          )
                        })}
                      </div>
                      <div className={classNames.servicecollection}>
                        {collection2.map((each) =>{
                          return(
                            <div className={classNames.eachservice} key={each.title}>
                              <p className={classNames.serviceName}>{each.title}</p>
                            </div>
                          )
                        })}
                      </div>
                      <div className={classNames.servicecollection}>
                        {collection3.map((each) =>{
                          return(
                            <div className={classNames.eachservice} key={each.title}>
                              <p className={classNames.serviceName}>{each.title}</p>
                            </div>
                          )
                        })}
                      </div>
                    </div>
                  </div>
            </section>
            <section className={classNames.mobrightssection}>
              <div className={classNames.rightsbox}>
              All Rights Reserved 2022 IndianOTC.com
              </div>
            </section>
            </div>
            <section className={classNames.aboutMock}>
                <AliceCarousel
                    mouseTracking
                    infinite
                    autoPlayInterval={2000}
                    // animationDuration={6500}
                    disableDotsControls
                    disableButtonsControls
                    autoPlay
                    responsive={responsive}
                    items={items}
                    keyboardNavigation={true}
                  />
            </section>
            <div className={classNames.stickyFooter}>
              <AnimatePresence exitBeforeEnter>
                {rainDrop ? (
                  <motion.div className={classNames.footerWrap} key="0">
                    <img src={raindrops} alt="" className={classNames.raindrops} />
                  </motion.div>
                ) : step ? (
                  <motion.div
                    className={`${classNames.footerWrap} ${classNames.sb}`}
                    initial={{ x: "30%", opacity: 0 }}
                    animate={{ x: 0, opacity: 1 }}
                    exit={{ x: "-30%", opacity: 0 }}
                    transition={{ ease: [0.17, 0.67, 0.83, 0.67] }}
                    key="1"
                  >
                    <img src={left} alt="" className={classNames.left} />
                    <div className={classNames.banner}>
                      <img src={exchange} alt="" className={classNames.logo} />
                      <span>Own India’s Next Big Cryptocurrency Exchange</span>
                    </div>
                    <img src={right} alt="" className={classNames.right} />
                  </motion.div>
                ) : (
                  <motion.div
                    className={`${classNames.footerWrap} ${classNames.sb}`}
                    initial={{ x: "30%", opacity: 0 }}
                    animate={{ x: 0, opacity: 1 }}
                    exit={{ x: "-30%", opacity: 0 }}
                    transition={{ ease: [0.17, 0.67, 0.83, 0.67], duration: 0.5 }}
                    key="2"
                  >
                    <img src={left} alt="" className={classNames.left} />
                    <div className={classNames.banner}>
                      <img src={im} alt="" className={classNames.logo} />
                      <span>Come Meet Us At The Upcoming IndianMarket Expo</span>
                    </div>
                    <img src={right} alt="" className={classNames.right} />
                  </motion.div>
                )}
              </AnimatePresence>
              </div>
        </div>
        <div className={classNames.allbelowsticky}>
        <section className={classNames.footersection} style={{display:'flex'}}>
                  <div className={classNames.engagebox} style={{width:'25%'}}>
                    <p className={classNames.text}>Engage With Us</p>
                    <div className={classNames.socialdata}>
                      {engageMenu.map((each, id) =>{
                        return(
                          <div className={classNames.eachmedia} key={id}>
                            <img className={classNames.mediaImg} src={each.menuicon} alt='' />
                            <p className={classNames.mediaName}>{each.menuItem}</p>
                          </div>
                        )
                      })}
                    </div>
                  </div>
                  <div className={classNames.engagebox} style={{width:'25%'}}>
                    <p className={classNames.text}>About IndianOTC</p>
                    <div className={classNames.socialdata}>
                      {aboutIndianOtcMenu.map((each, id) =>{
                        return(
                          <div className={classNames.eachmedia} key={id}>
                            <img className={classNames.mediaImg} src={each.menuicon} alt='' />
                            <p className={classNames.mediaName}>{each.menuItem}</p>
                          </div>
                        )
                      })}
                    </div>
                  </div>
                  <div className={classNames.engagebox} style={{width:'50%'}}>
                    <p className={classNames.text}>Popular Services</p>
                    <div className={classNames.servicedata}>
                      <div className={classNames.servicecollection}>
                        {collection1.map((each) =>{
                          return(
                            <div className={classNames.eachservice} key={each.title}>
                              <p className={classNames.serviceName}>{each.title}</p>
                            </div>
                          )
                        })}
                      </div>
                      <div className={classNames.servicecollection}>
                        {collection2.map((each) =>{
                          return(
                            <div className={classNames.eachservice} key={each.title}>
                              <p className={classNames.serviceName}>{each.title}</p>
                            </div>
                          )
                        })}
                      </div>
                      <div className={classNames.servicecollection}>
                        {collection3.map((each) =>{
                          return(
                            <div className={classNames.eachservice} key={each.title}>
                              <p className={classNames.serviceName}>{each.title}</p>
                            </div>
                          )
                        })}
                      </div>
                    </div>
                  </div>
            </section>
            <section className={classNames.rightssection}>
          <div className={classNames.rightsbox}>
          All Rights Reserved 2022 IndianOTC.com
          </div>
        </section>
        </div>
        </div>
        {loginModalOpen && (
          <LoginModal
            onClose={() => setLoginModalOpen(false)}
            onSuccess={() => {
              setLoginModalOpen(false);
              navigate("/trading/vaults");
            }}
          />
        )}
      </>
    </MainLayout>
  );
}

export default AboutPage;
