import classNames from "./topNavbarFundsSubMenu.module.scss";

import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import fundsIcon from "../../static/images/sidebarIcons/funds/funds.svg";
import indexfundcoins from "../../static/images/sidebarIcons/funds/indexfundcoins.svg";
import launchpad from "../../static/images/sidebarIcons/funds/launchpad.svg";
import tradersIcon from "../../static/images/sidebarIcons/funds/traders.svg";
import marketWatchersIcon from "../../static/images/sidebarIcons/funds/marketWatchers.svg";
import launchIcon from "../../static/images/sidebarIcons/funds/launch.svg";
import competitionsIcon from "../../static/images/sidebarIcons/funds/competitions.svg";
import fireIcon from "../../static/images/actionIcons/funds/competition.svg";
import fundIcon from "../../static/images/actionIcons/funds/fund.svg";
import investIcon from "../../static/images/actionIcons/funds/invest.svg";
import meetIcon from "../../static/images/actionIcons/funds/meet.svg";
import pickIcon from "../../static/images/actionIcons/funds/pick.svg";
import signalIcon from "../../static/images/actionIcons/funds/signal.svg";

const SIDEBAR_LIST = [
  {
    label: "FundCoins",
    icon: fundsIcon,
    link: "/",
  },
  {
    label: "Index-FundCoins",
    icon: indexfundcoins,
    link: "",
    disable: false,
  },
  {
    label: "Traders",
    icon: tradersIcon,
    link: "",
    disable: false,
  },
  {
    label: "MarketWatchers",
    icon: marketWatchersIcon,
    link: "",
    disable: false,
  },
  {
    label: "Launch Fund",
    icon: launchpad,
    href: "",
  },
];

const ACTIONS_LIST = [
  {
    title: "What Is An FundCoin?",
    subTitle: "Learn About IFO’s",
    icon: fundsIcon,
  },
  {
    title: "What Is MarketWatchers",
    subTitle: "Learn About MarketWatchers",
    icon: marketWatchersIcon,
  },
  {
    title: "Fund Types",
    subTitle: "See All Fund Types",
    icon: fundIcon,
  },
  {
    title: "Meet The Fund Managers",
    subTitle: "See All The Traders",
    icon: meetIcon,
  },
  {
    title: "Invest Into FundCoins",
    subTitle: "How To Buy A FundCoin",
    icon: investIcon,
  },
  {
    title: "Picking A Fund Manager",
    subTitle: "Select The Right Manager",
    icon: pickIcon,
  },
  {
    title: "Trading Competitions",
    subTitle: "Watch The Best Traders",
    icon: fireIcon,
  },
  {
    title: "Become A FundManager",
    subTitle: "Launch Your IFO Today",
    icon: launchIcon,
  },
  {
    title: "Become A Signal Provider",
    subTitle: "Launch Your Subscription Today",
    icon: signalIcon,
  },
];

function TopNavbarFundsSubMenu({
  openTab,
  setOpenTab,
}: {
  openTab: boolean;
  setOpenTab: any;
}) {
  const { pathname } = useLocation();
  return (
    <div
      className={`${classNames.topNavbarFundsSubMenuWrap} ${
        classNames[openTab.toString()]
      }`}
    >
      {openTab && (
        <div className={classNames.overlay} onClick={() => setOpenTab("")} />
      )}
      <div className={classNames.topNavbarFundsSubMenu}>
        <div className={classNames.subMenuList}>
          {SIDEBAR_LIST.map((menu, i) => (
            <Link
              key={menu.label}
              to={menu.disable ? "" : menu.link ? `/funds${menu.link}` : ""}
              onClick={(e) => {
                if (menu.href) {
                  window.open(menu.href, "_blank");
                }
              }}
              className={`${
                classNames[
                  (
                    pathname === `/funds${menu.link}` ||
                    (/\/terminals\/^\s\/^\s/i.test(pathname) &&
                      menu.link === "/BTC/USDT")
                  ).toString()
                ]
              } ${classNames[menu.disable ? "dis" : ""]} ${
                classNames.sideMenu
              }`}
            >
              <img src={menu.icon} alt="" />
              <span>{menu.label}</span>
            </Link>
          ))}
        </div>
        <div className={classNames.tabDetailContent}>
          <div className={classNames.titlle}>FundCoins FAQ’s</div>
          <div className={classNames.subtitle}>
          Speak To An Funds Specialist By Clicking <strong>Here</strong>
          </div>
          <div className={classNames.actionList}>
            {ACTIONS_LIST.map((action: any, i) => (
              <div
                className={`${classNames.action} ${
                  classNames[action.disable ? "disable" : "enable"]
                }`}
                key={i}
              >
                <img src={action.icon} alt="" className={classNames.icon} />
                <div className={classNames.detail}>
                  <div className={classNames.actName}>{action.title}</div>
                  <div className={classNames.actDesc}>{action.subTitle}</div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default TopNavbarFundsSubMenu;
