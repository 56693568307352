import { useCallback } from "react";
import pasteIcon from "../../static/images/clipIcons/pasteIcon.svg";
import classNames from "./pasteTextInput.module.scss";

function PasteTextInput({
  value,
  setValue = () => {},
  type = "text",
  className,
  placeholder,
}: {
  value?: string;
  type?: string;
  className?: string;
  setValue?: any;
  placeholder?: string;
}) {
  const onPaste = useCallback(() => {
    navigator.clipboard.readText().then((clipText) => setValue(clipText));
  }, [setValue]);

  return (
    <label className={`${className} ${classNames.inpWrap}`}>
      <input
        className={classNames.input}
        type={type}
        value={value}
        onChange={(e) => setValue(e.target.value)}
        placeholder={placeholder}
      />
      <img
        src={pasteIcon}
        alt=""
        className={classNames.iconPaste}
        onClick={onPaste}
      />
    </label>
  );
}

export default PasteTextInput;
