import PreregisterBox from "../../components/PreregisterBox";
import MainLayout from "../../layouts/MainLayout";
import classNames from "./institutionalPreRegPage.module.scss";

function InstitutionalPreRegPage(): JSX.Element {
  return (
    <MainLayout className={classNames.institutionalPreRegPage}>
      <PreregisterBox />
    </MainLayout>
  );
}

export default InstitutionalPreRegPage;
