import { memo, useEffect, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import classNames from "./homeStickyFooter.module.scss";
import raindrops from "../../static/images/stickyFooterIcons/raindrops.svg";
import exchange from "../../static/images/stickyFooterIcons/exchange.svg";
import im from "../../static/images/stickyFooterIcons/im.svg";
import left from "../../static/images/stickyFooterIcons/left.svg";
import right from "../../static/images/stickyFooterIcons/right.svg";
import { duration } from "moment";

function HomeStickyFooter() {
  const [rainDrop, setRainDrop] = useState(true);
  const [step, setStep] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setRainDrop(false);
      setInterval(() => {
        setStep((prev) => !prev);
      }, 3000);
    }, 2000);
  }, []);

  return (
    <div className={classNames.stickyFooter}>
      <AnimatePresence exitBeforeEnter>
        {rainDrop ? (
          <motion.div className={classNames.footerWrap} key="0">
            <img src={raindrops} alt="" className={classNames.raindrops} />
          </motion.div>
        ) : step ? (
          <motion.div
            className={`${classNames.footerWrap} ${classNames.sb}`}
            initial={{ x: "30%", opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            exit={{ x: "-30%", opacity: 0 }}
            transition={{ ease: [0.17, 0.67, 0.83, 0.67] }}
            key="1"
          >
            <img src={left} alt="" className={classNames.left} />
            <div className={classNames.banner}>
              <img src={exchange} alt="" className={classNames.logo} />
              <span>Own India’s Next Big Cryptocurrency Exchange</span>
            </div>
            <img src={right} alt="" className={classNames.right} />
          </motion.div>
        ) : (
          <motion.div
            className={`${classNames.footerWrap} ${classNames.sb}`}
            initial={{ x: "30%", opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            exit={{ x: "-30%", opacity: 0 }}
            transition={{ ease: [0.17, 0.67, 0.83, 0.67], duration: 0.5 }}
            key="2"
          >
            <img src={left} alt="" className={classNames.left} />
            <div className={classNames.banner}>
              <img src={im} alt="" className={classNames.logo} />
              <span>Come Meet Us At The Upcoming IndianMarket Expo</span>
            </div>
            <img src={right} alt="" className={classNames.right} />
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}

export default HomeStickyFooter;
