import React, { memo, useState } from "react";
import classNames from "./terminalsBuySellSidebar.module.scss";

const TerminalsBuySellSidebar: React.FC<{}> = () => {
  const [action, setAction] = useState("Buy");
  return (
    <div className={classNames.sidebar}>
      <div className={classNames.tabs}>
        <div className={`${classNames.tab} ${classNames.true}`}>Market</div>
        <div className={classNames.tab}>Limit</div>
        <div className={classNames.tab}>Stop Limit</div>
      </div>
      <div className={classNames.marketContent}>
        <div className={classNames.btnSwitch}>
          <div
            className={`${classNames.btn} ${
              classNames[(action === "Buy").toString()]
            }`}
            onClick={() => setAction("Buy")}
          >
            Buy
          </div>
          <div
            className={`${classNames.btn} ${
              classNames[(action === "Sell").toString()]
            }`}
            onClick={() => setAction("Sell")}
          >
            Sell
          </div>
        </div>
        <label className={classNames.inputWrap}>
          <div className={classNames.label}>Price</div>
          <input
            type="number"
            className={classNames.input}
            placeholder="Market"
            readOnly
          />
          <div className={classNames.symbol}>INR</div>
        </label>
        <label className={classNames.inputWrap}>
          <div className={classNames.label}>Total</div>
          <input
            type="number"
            className={classNames.input}
            placeholder="0.00"
          />
          <div className={classNames.symbol}>INR</div>
        </label>
        <div
          className={`${classNames.btnFooter} ${
            classNames[(action === "Sell").toString()]
          }`}
        >
          {action} BTC
        </div>
      </div>
    </div>
  );
};
export default memo(TerminalsBuySellSidebar);
