import { memo } from "react";
import Skeleton from "react-loading-skeleton";
import { useNavigate } from "react-router-dom";
import { DISP_CURRENCY_SYM, DISP_CURRENCY } from "../../configs";
import { FormatCurrency, FormatNumber } from "../../utils/FunctionTools";
import classNames from "./fundsMarketPathsTable.module.scss";

function FundsMarketPathsTable({
  pathList,
  isLoading,
}: {
  pathList: [];
  isLoading: boolean;
}) {
  const navigate = useNavigate();
  return (
    <div className={classNames.fundsMarketPathsTable}>
      <div className={classNames.tableHeader}>
        <div className={classNames.asset}>Fund</div>
        <div className={classNames.price}>Price</div>
        <div className={classNames.ch24}>ROI (%)</div>
        <div className={classNames.supply}>Supply</div>
        <div className={classNames.marCap}>Marketcap</div>
        <div className={classNames.volume24}>Investors</div>
      </div>
      {isLoading
        ? Array(6)
            .fill("")
            .map((_, i) => (
              <div
                className={classNames.coinItem}
                key={i}
                style={{
                  padding: "0 40px",
                  justifyContent: "space-between",
                }}
              >
                <div className={classNames.asset}>
                  <Skeleton width={30} height={30} />
                  <Skeleton className={classNames.coinName} width={120} />
                </div>
                <Skeleton className={classNames.price} width={120} />
                <Skeleton className={classNames.ch24} width={120} />
                <Skeleton className={classNames.supply} width={120} />
                <Skeleton className={classNames.marCap} width={120} />
                <Skeleton className={classNames.volume24} width={120} />
              </div>
            ))
        : pathList?.map((path: any, index: number) => (
            <div
              className={classNames.coinItem}
              key={index}
              onClick={() => navigate(path.path_id)}
            >
              <div className={classNames.asset}>
                <img src={path?.token_profile_data?.coinImage} alt="" />
                <div className={classNames.detail}>
                  <div className={classNames.coinName}>
                    {path?.token_profile_data?.coinName}
                  </div>
                  <div className={classNames.coin}>
                    {path?.token_profile_data?.coinSymbol}
                    <img
                      src={path?.asset_metaData?.coinImage}
                      alt=""
                      className={classNames.flag}
                    />
                    <span>{path.asset}</span>
                  </div>
                </div>
              </div>
              <div className={classNames.price}>
                {DISP_CURRENCY_SYM}
                {FormatCurrency(path.token_price, DISP_CURRENCY)}
              </div>
              <div
                className={`${classNames.ch24} ${
                  classNames[(1 < 0).toString()]
                }`}
              >
                {DISP_CURRENCY_SYM}
                {FormatCurrency(Math.abs(0), DISP_CURRENCY)}(
                {Math.sign(0) === -1 ? "-" : "+"}
                {FormatNumber(Math.abs(0), 2)}%)
              </div>
              <div className={`${classNames.supply} ${""}`}>
                {DISP_CURRENCY_SYM}
                {FormatCurrency(path.circulating_supply, DISP_CURRENCY)}
              </div>
              <div className={classNames.marCap}>
                {DISP_CURRENCY_SYM}
                {FormatCurrency(path.asset_balance, DISP_CURRENCY)}
              </div>
              <div className={classNames.volume24}>
                {FormatNumber(path.tokenHoldersCount)}
                <div
                  className={`${classNames.btnTrade}`}
                  onClick={() => navigate(path.path_id)}
                >
                  Buy
                </div>
              </div>
            </div>
          ))}
    </div>
  );
}

export default memo(FundsMarketPathsTable);
