import React, { memo } from "react";
import { useNavigate } from "react-router-dom";
import classNames from "./newsCarousel.module.scss";
import oneBg from "../../static/images/newsCarouselBgs/indianInvestor.jpg";
import twoBg from "../../static/images/newsCarouselBgs/indianMarket.jpg";
import threeBg from "../../static/images/newsCarouselBgs/indianOTC.jpg";
import fourBg from "../../static/images/newsCarouselBgs/indianOTC-1.jpg";
import fiveBg from "../../static/images/newsCarouselBgs/affiliateApp.jpg";

const NEWS_LIST = [
  {
    title: "IndianOTC ShareToken Offering",
    source: "IndianInvestor",
    img: oneBg,
  },
  {
    title: "Learn About IndianOTC’s Founder",
    source: "IndianMarket",
    img: twoBg,
    link: "/founders/rohith",
  },
  {
    title: "Join Waiting List For Indra Tokens",
    source: "IndianOTC",
    img: threeBg,
  },
  {
    title: "Pre-Register Your Fund Today",
    source: "IndianOTC",
    img: fourBg,
    link: "/institutional/funds/preregister",
  },
  {
    title: "Earn From Your Friends Trades",
    source: "Affiliate App",
    img: fiveBg,
  },
];

const NewsCarousel: React.FC<{}> = () => {
  const navigate = useNavigate();
  return (
    <div className={classNames.newsCarousel}>
      <div className={classNames.newsList}>
        {NEWS_LIST.map((news, i) => (
          <div
            className={classNames.newsCard}
            key={i}
            onClick={() => {
              if (news.link) navigate(news.link);
            }}
          >
            <img src={news.img} alt="" />
            <div className={classNames.source}>{news.source}</div>
            <div className={classNames.title}>{news.title}</div>
          </div>
        ))}
      </div>
    </div>
  );
};
export default memo(NewsCarousel);
