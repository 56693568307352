import { Provider, ErrorBoundary } from "@rollbar/react";
import { useEffect } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { ToastContainer } from "react-toastify";
import { useAppDispatch, useAppSelector } from "./app/hooks";
import { selectLoginData, updateTokenAsync } from "./app/loginSlice";
import Routes from "./Routes";



function App() {
  // Roll Bar Config For Crash Reports
  const rollbarConfig = {
    accessToken: "e55ca5430fdc48f8aa27244c5ca2527f",
    captureUncaught: true,
    captureUnhandledRejections: true,
    environment:
      !process.env.NODE_ENV || process.env.NODE_ENV === "development"
        ? "development"
        : "production",
    server: {
      root: "https://indianotc.com/",
      branch: "main",
    },
  };

  // Query Client
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: true,
      },
    },
  });

  // Update Token On Every Reload
  const dispatch = useAppDispatch();
  const { deviceId, refreshToken } = useAppSelector(selectLoginData);
  useEffect(() => {
    dispatch(updateTokenAsync(deviceId, refreshToken));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Provider config={rollbarConfig}>
      <ErrorBoundary>
        <QueryClientProvider client={queryClient}>
          <Routes />
        </QueryClientProvider>
        <ToastContainer />
      </ErrorBoundary>
    </Provider>
  );
}

export default App;
