import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import classNames from "./topNavbarIIMSubMenu.module.scss";
import { selectLoginData } from "../../app/loginSlice";
import cryptobroker from '../../static/images/sidebarIcons/broker/cryptobroker.svg';
import exchangefees from '../../static/images/sidebarIcons/broker/exchangefees.svg';
import affliate from '../../static/images/sidebarIcons/broker/affliate.svg';
import find from '../../static/images/sidebarIcons/broker/find.svg';
import brokeracademy from '../../static/images/sidebarIcons/broker/brokeracademy.svg';


const SIDEBAR_LIST = [
  {
    label: "CryptoBroker",
    icon: cryptobroker,
    link: "",
  },
  {
    label: "Exchange Fees",
    icon: exchangefees,
    link: "",
  },
  {
    label: "Affiliate App",
    icon: affliate,
    link: "",
    // login: true,
  },
  {
    label: "Find A Broker",
    icon: find,
    link: "",
    // disable: false,
    // login: true,
  },
  {
    label: "Broker Academy",
    icon: brokeracademy,
    link: "",
  },
];

const ACTIONS_LIST = [
  {
    title: "What Is CryptoBroker?",
    subTitle: "CryptoBrokers",
    icon: '',
  },
  {
    title: "Can I Refer A Broker?",
    subTitle: "CryptoBrokers",
    icon: '',
  },
  {
    title: "Is Their Training For Brokers?",
    subTitle: "CryptoBrokers",
    icon: '',
  },
  {
    title: "Who Can Be A Broker?",
    subTitle: "CryptoBrokers",
    icon: '',
  },
  {
    title: "Become A CryptoBroker",
    subTitle: "CryptoBrokers",
    icon: '',
  },
  {
    title: "Is It Free To Be A Broker?",
    subTitle: "CryptoBrokers",
    icon: '',
  },
  {
    title: "How Do Brokers Earn?",
    subTitle: "CryptoBrokers",
    icon: '',
  },
  {
    title: "Download Affiliate.App",
    subTitle: "Getting Started",
    icon: '',
  },
  {
    title: "",
    subTitle: "",
    icon: '',
  },
];

function TopNavbarIIMSubMenu({
  openTab,
  setOpenTab,
  setLoginedPath,
  setLoginModalOpen,
}: {
  openTab: boolean;
  setOpenTab: { (value: string): void };
  setLoginedPath: { (value: string): void };
  setLoginModalOpen: { (value: boolean): void };
}) {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { email } = useSelector(selectLoginData);
  return (
    <div
      className={`${classNames.otcSubMenuWrap} ${
        classNames[openTab.toString()]
      }`}
    >
      {openTab && (
        <div className={classNames.overlay} onClick={() => setOpenTab("")} />
      )}
      <div className={classNames.otcSubMenu}>
        <div className={classNames.subMenuList}>
          {SIDEBAR_LIST.map((menu, i) => (
            <div
              key={menu.label}
              onClick={() => {
                // navigate(`/trading${menu.link}`);
              }}
              className={` ${
                classNames.sideMenu
              }`}
            >
              <img src={menu.icon} alt="" />
              <span>{menu.label}</span>
            </div>
          ))}
        </div>
        <div className={classNames.tabDetailContent}>
          <div className={classNames.titlle}>CryptoBrokers FAQ’s</div>
          <div className={classNames.subtitle}>
          Speak To An CryptoBroker Specialist By Clicking <strong>Here</strong>
          </div>
          <div className={classNames.actionList}>
            {ACTIONS_LIST.map((action: any, i) => (
              <div className={classNames.action} key={i}>
                <div className={classNames.icon} style={{display: action.title === '' ? 'none' : ''}}>  </div>
                <div className={classNames.detail}>
                  <div className={classNames.actName}>{action.title}</div>
                  <div className={classNames.actDesc}>{action.subTitle}</div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default TopNavbarIIMSubMenu;
