import { useDispatch, useSelector } from "react-redux";
import { setPair, setQuoteCoin } from "../../app/terminalsSlice";
import classNames from "./terminalsPage.module.scss";
import MainLayout from "../../layouts/MainLayout";
import TerminalsOrderBook from "../../components/TerminalsOrderBook";
import TerminalsChartSection from "../../components/TerminalsChartSection";
import { ReactComponent as SearchIcon } from "../../static/images/clipIcons/searchIcon.svg";
import { useEffect, useState } from "react";
import { usePairByQuotes, usePairQuotes } from "../../queryHooks";
import { FormatCurrency } from "../../utils/FunctionTools";
import { RootState } from "../../app/store";
import Skeleton from "react-loading-skeleton";
import TerminalsBuySellSidebar from "../../components/TerminalsBuySellSidebar";
import { useNavigate, useParams } from "react-router-dom";

function TerminalsPage(): JSX.Element {
  const terminalsData = useSelector((state: RootState) => state.terminalsData);
  return (
    <MainLayout
      className={classNames.terminalsPage}
      searchComponent={<SearchComponent />}
    >
      <div className={classNames.pageWrap}>
        <div className={classNames.mainContents}>
          <div className={classNames.coinDetail}>
            <div className={classNames.pair}>{terminalsData?.pair}</div>
            <div className={classNames.property}>
              <div className={classNames.label}>Price</div>
              <div className={classNames.price}>
                {FormatCurrency(
                  terminalsData?.pairPrice,
                  terminalsData?.quoteCoin
                )}
              </div>
            </div>
            <div className={classNames.property}>
              <div className={classNames.label}>24Hr Change</div>
              <div className={classNames.price}>32,321.32</div>
            </div>
            <div className={classNames.property}>
              <div className={classNames.label}>24Hr High</div>
              <div className={classNames.price}>32,321.32</div>
            </div>
            <div className={classNames.property}>
              <div className={classNames.label}>24Hr Low</div>
              <div className={classNames.price}>32,321.32</div>
            </div>
            <div className={classNames.property}>
              <div className={classNames.label}>24Hr Volume</div>
              <div className={classNames.price}>32,321.32</div>
            </div>
          </div>
          <div className={classNames.chartOrderBookWrap}>
            <TerminalsChartSection />
            <TerminalsOrderBook />
          </div>
          <div className={classNames.topSignals}>
            <div className={classNames.traders}>
              <div className={classNames.tabs}>
                <div className={`${classNames.tab} ${classNames.false}`}>
                  Top Traders
                </div>
                <div className={`${classNames.tab} ${classNames.true}`}>
                  My Funds
                </div>
              </div>
            </div>
            <div className={classNames.signals}>
              <div className={classNames.tabs}>
                <div className={`${classNames.tab} ${classNames.false}`}>
                  Signals
                </div>
                <div className={`${classNames.tab} ${classNames.true}`}>
                  Market News
                </div>
              </div>
            </div>
            <div className={classNames.orders}>
              <div className={classNames.tabs}>
                <div className={`${classNames.tab} ${classNames.false}`}>
                  Trades
                </div>
                <div className={`${classNames.tab} ${classNames.true}`}>
                  My Orders
                </div>
              </div>
            </div>
          </div>
        </div>
        <TerminalsBuySellSidebar />
      </div>
    </MainLayout>
  );
}

function SearchComponent(): JSX.Element {
  const { base, quote } = useParams<string>();
  const navigate = useNavigate();
  const terminalState = useSelector((state: RootState) => state.terminalsData);
  const dispatch = useDispatch();
  const [focused, setFocused] = useState(false);
  const { data: pairQuotes } = usePairQuotes();
  const { data: pairByQuote, isLoading: pairsLoading } = usePairByQuotes(
    terminalState?.quoteCoin
  );
  const [search, setSearch] = useState("");
  useEffect(() => {
    dispatch(setQuoteCoin(`${quote}`));
    dispatch(
      setPair({
        pair: `${base}/${quote}`.toUpperCase(),
        pairPrice: pairByQuote?.[`${base}/${quote}`.toUpperCase()],
      })
    );
  }, [base, dispatch, pairByQuote, quote]);

  return (
    <div className={classNames.searchWrapOut}>
      <label className={classNames.searchWrap}>
        <SearchIcon className={classNames.icon} />
        <input
          type="text"
          placeholder="Search For Trading Pairs In Terminal..."
          className={classNames.search}
          onFocus={() => setFocused(true)}
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
      </label>
      {focused && (
        <>
          <div
            className={classNames.wrapOutline}
            onClick={() => setFocused(false)}
          />
          <div className={classNames.searchRes}>
            <div className={classNames.topListWrap}>
              <div className={classNames.topList}>
                {pairQuotes?.map((coin: any) => (
                  <div
                    className={`${classNames.coinQuote} ${
                      classNames[
                        (coin.quote === terminalState?.quoteCoin).toString()
                      ]
                    }`}
                    key={coin.quote}
                    onClick={() => dispatch(setQuoteCoin(coin.quote))}
                  >
                    <img src={coin.quoteImage} alt="" />
                    <span>{coin.quote}</span>
                  </div>
                ))}
              </div>
            </div>
            <div className={classNames.pairsList}>
              {pairsLoading
                ? Array(10)
                    .fill("")
                    .map((_, i) => (
                      <div className={classNames.pair} key={i}>
                        <div className={classNames.pairAsset}>
                          <Skeleton circle className={classNames.icon} />
                          <Skeleton className={classNames.key} width={140} />
                        </div>
                        <Skeleton className={classNames.value} width={140} />
                        <Skeleton
                          className={classNames.change}
                          width={140}
                          style={{ marginLeft: 20 }}
                        />
                      </div>
                    ))
                : pairByQuote &&
                  Object.keys(pairByQuote)
                    .filter((key) =>
                      key.toLowerCase().includes(search.toLowerCase())
                    )
                    .map((key) => (
                      <div
                        className={classNames.pair}
                        key={key}
                        onClick={() => {
                          dispatch(
                            setPair({
                              pair: key,
                              pairPrice: pairByQuote?.[key],
                            })
                          );
                          navigate(`/terminals/${key}`);
                        }}
                      >
                        <div className={classNames.pairAsset}>
                          <img src="" alt="" className={classNames.icon} />
                          <span className={classNames.key}>{key}</span>
                        </div>
                        <span className={classNames.value}>
                          {FormatCurrency(
                            pairByQuote?.[key],
                            terminalState?.quoteCoin
                          )}
                        </span>
                        <span className={classNames.change}>
                          {FormatCurrency(0)}
                        </span>
                      </div>
                    ))}
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default TerminalsPage;
