import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import classNames from "./topNavbarOTCSubMenu.module.scss";
import p2p from "../../static/images/sidebarIcons/p2p.svg";
import dashboardIcon from "../../static/images/sidebarIcons/dashboard.svg";
import fundIcon from "../../static/images/sidebarIcons/fund.svg";
import vaultsIcon from "../../static/images/sidebarIcons/vaults.svg";
import withdrawIcon from "../../static/images/sidebarIcons/withdraw.svg";
import otcdesk from "../../static/images/sidebarIcons/otcdesk.svg";
import otcbots from "../../static/images/sidebarIcons/otcbots.svg";
import terminals from "../../static/images/sidebarIcons/terminals.svg";
import { selectLoginData } from "../../app/loginSlice";
import btc from "../../static/images/actionIcons/btc.svg";
import cash from "../../static/images/actionIcons/cash.svg";
import eth from "../../static/images/actionIcons/eth.svg";
import inr from "../../static/images/actionIcons/inr.svg";
import usdt from "../../static/images/actionIcons/usdt.svg";
import wallet from "../../static/images/actionIcons/wallet.svg";
import tokenSwapIcon from "../../static/images/sidebarIcons/tokeng.svg";

const SIDEBAR_LIST = [
  {
    label: "OTCDesk",
    icon: otcdesk,
    link: "/otcdesk",
  },
  {
    label: "OTCBots",
    icon: otcbots,
    link: "",
    disable: true,
  },
  {
    label: "TokenSwap",
    icon: tokenSwapIcon,
    link: "/tokenSwap",
    disable: true,
  },
  {
    label: "Terminal",
    icon: terminals,
    link: "",
    login: true,
  },
  {
    label: "Markets",
    icon: dashboardIcon,
    link: "/markets",
  },
];

const ACTIONS_LIST = [
  {
    title: "Buy Bitcoin",
    subTitle: "With INR",
    icon: btc,
  },
  {
    title: "Sell Bitcoin",
    subTitle: "Into INR",
    icon: btc,
  },
  {
    title: "Deposit INR",
    subTitle: "From Bank Account",
    icon: inr,
  },
  {
    title: "Buy Ethereum",
    subTitle: "With INR",
    icon: eth,
  },
  {
    title: "Sell Ethereum",
    subTitle: "Into INR",
    icon: eth,
  },
  {
    title: "Deposit Crypto",
    subTitle: "To External Wallets",
    icon: wallet,
  },
  {
    title: "Buy USDT",
    subTitle: "With INR",
    icon: usdt,
  },
  {
    title: "Sell USDT",
    subTitle: "Into INR",
    icon: usdt,
  },
  {
    title: "Withdraw Funds",
    subTitle: "To Bank Account",
    icon: cash,
  },
];

function OTCSubMenu({
  openTab,
  setOpenTab,
  setLoginedPath,
  setLoginModalOpen,
}: {
  openTab: boolean;
  setOpenTab: { (value: string): void };
  setLoginedPath: { (value: string): void };
  setLoginModalOpen: { (value: boolean): void };
}) {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { email } = useSelector(selectLoginData);
  return (
    <div
      className={`${classNames.otcSubMenuWrap} ${
        classNames[openTab.toString()]
      }`}
    >
      {openTab && (
        <div className={classNames.overlay} onClick={() => setOpenTab("")} />
      )}
      <div className={classNames.otcSubMenu}>
        <div className={classNames.subMenuList}>
          {SIDEBAR_LIST.map((menu, i) => (
            <div
              key={menu.label}
              onClick={() => {
                if (!menu.disable) {
                  if (menu.login && !email) {
                    setLoginedPath(`/trading${menu.link}`);
                    setLoginModalOpen(true);
                    setOpenTab("");
                  } else {
                    navigate(`/trading${menu.link}`);
                  }
                }
              }}
              className={`${
                classNames[
                  (
                    pathname === `/trading${menu.link}` ||
                    (pathname === "/trading" && menu.link === "/otcdesk")
                  ).toString()
                ]
              } ${classNames[menu.disable ? "dis" : ""]} ${
                classNames.sideMenu
              }`}
            >
              <img src={menu.icon} alt="" />
              <span>{menu.label}</span>
            </div>
          ))}
        </div>
        <div className={classNames.tabDetailContent}>
          <div className={classNames.titlle}>Trading FAQ’s</div>
          <div className={classNames.subtitle}>
          Speak To An Trading Specialist By Clicking <strong>Here</strong>
          </div>
          <div className={classNames.actionList}>
            {ACTIONS_LIST.map((action: any, i) => (
              <div className={classNames.action} key={i}>
                <img src={action.icon} alt="" className={classNames.icon} />
                <div className={classNames.detail}>
                  <div className={classNames.actName}>{action.title}</div>
                  <div className={classNames.actDesc}>{action.subTitle}</div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default OTCSubMenu;
