import * as d3 from "d3";

const Arc = ({
  data,
  index,
  createArc,
  onMouseOver,
  onMouseOut,
  segment,
}: any) => {
  return (
    <g key={index} className="arc">
      <path
        opacity={segment === index || segment === null ? 1 : 0.1}
        onMouseEnter={() => onMouseOver(index)}
        onMouseLeave={() => onMouseOut()}
        className="arc"
        d={createArc(data)}
        fill={data?.data?.color}
      />
    </g>
  );
};

const DonutChart = ({
  onMouseOver,
  onMouseOut,
  pieData,
  segment,
  className,
}: any) => {
  const innerRadius = 40;
  const outerRadius = 150;
  const createPie = d3
    .pie()
    .value((d: any) => d.value)
    .sort(null);
  const createArc = d3.arc().innerRadius(innerRadius).outerRadius(outerRadius);
  const data = createPie(pieData);
  return (
    <svg viewBox="0 0 300 300" className={`${className}`}>
      <g transform={`translate(${outerRadius} ${outerRadius})`}>
        {data.map((d: any, i: number) => (
          <Arc
            key={i}
            data={d}
            index={i}
            createArc={createArc}
            onMouseOver={onMouseOver}
            onMouseOut={onMouseOut}
            segment={segment}
          />
        ))}
      </g>
    </svg>
  );
};

export default DonutChart;
