import { useCallback, useEffect, useRef, useState } from "react";
import classNames from "./otcHome.module.scss";
import { Link, useNavigate } from "react-router-dom";
import MainLayout from "../../layouts/MainLayout";

const allCards = [
    {
        key:0,
        title:'I Want To Learn About Crypto',
        description:'Buy and sell popular cryptocurrencies. Invest in Funds managed by expert traders.',
        buttonTitle:'Learn',
        buttonColor:' #F2A900'
        
    },
    {
        key:1,
        title:'I Want To Buy/Sell Crypto Right Now',
        description:'Buy and sell popular cryptocurrencies. Invest in Funds managed by expert traders.',
        buttonTitle:'Trade',
        buttonColor:'#5F6163'
    },
    {
        key:2,
        title:'I Want To See What You Offer',
        description:'Buy and sell popular cryptocurrencies. Invest in Funds managed by expert traders.',
        buttonTitle:'Features',
        buttonColor:'#F2A900'
    }
]

function OTCHomePage(): JSX.Element {

  return (
    <MainLayout
      className={classNames.landingPage}
    >
      <div className={classNames.pageWrap}>
        <section className={classNames.startSection}>
            <h1 className={classNames.head}>Buy Bitcoin In India Easily</h1>
            <h1 className={classNames.titleSubTitle} style={{ width:"43%",textAlign: "center",
          lineHeight: "2",fontWeight: "300", fontSize: "22px"}}>Invest in Bitcoin, Ethereum, 
            &amp; other 200+ crypto assets. 
            Download the app now, register &amp; start with as low as ₹100.</h1>
            <div className={classNames.btns}>
                <Link to="/getstarted" className={classNames.btnLearn}>
                    Get Started
                </Link>
            </div>
            <div className={classNames.indvcards}></div>
            <div className={classNames.description}> </div>
        </section>
        <section className={classNames.aboutSection}>
            <h1 className={classNames.head}>What Are You Looking For?</h1>
            <div className={classNames.aboutCards}>
                {allCards.map((card, i) => (
                    <div className={classNames.indvcards} style={{margin: i!=0 ? '0 0 0 100px': '0 0 0 0'}}>
                            <h1 className={classNames.title}>{card.title}</h1>
                            <p className={classNames.description}>{card.description}</p>
                            <button className={classNames.button1} style={{backgroundColor:card.buttonColor}}>
                                <span><h1 className={classNames.btntxt}>{card.buttonTitle}</h1></span></button>
                        </div>
                    )
                )}
            </div>
        </section>
        <div className={classNames.underLine}> </div>
      </div>
    </MainLayout>
  );
}

export default OTCHomePage;
