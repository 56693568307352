import { Link } from "react-router-dom";
import { FULL_LOGO } from "../../configs";
import PasteTextInput from "../PasteTextInput";
import classNames from "./getStartedAffiliate.module.scss";
function GetStartedAffiliate() {
  return (
    <>
      <div className={classNames.textSide}>
        <div className={classNames.title}>
          That’s Great. We Love Our Affiliates
        </div>
      </div>
      <div className={classNames.contentCards}>
        <img src={FULL_LOGO} alt="" className={classNames.logo} />
        <div className={classNames.desc}>
          Please Enter You’re Affiliate’s NetworkSync Code
        </div>
        <PasteTextInput placeholder="friends@gmail.com...." />
        <div className={classNames.btns}>
          <Link to="/getstarted" className={classNames.btnGoBack}>
            Go Back
          </Link>
          <div className={classNames.btnProceed}>Proceed</div>
        </div>
      </div>
    </>
  );
}

export default GetStartedAffiliate;
