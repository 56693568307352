import axios from "axios";
import { useEffect, useMemo, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { useParams } from "react-router-dom";
import moment from "moment";
import classNames from "./virtualProspectusVaults.module.scss";
import { useCoinList, useTokenDetailByPath } from "../../queryHooks";
import { FormatCurrency } from "../../utils/FunctionTools";
import calculator from "../../static/images/icons/calculator.svg";
import wallet from "../../static/images/icons/wallet.svg";

function VirtualProspectusVaults() {
  const { pathId } = useParams();
  const { data: tokenSelected } = useTokenDetailByPath(pathId);
  const [loading, setLoading] = useState(false);
  const [coinList, setCoinList] = useState<any[]>([]);
  const [coinSelected, setCoinSelected] = useState<any>();
  const [search, setSearch] = useState("");
  const [isVault, setIsVault] = useState(true);
  useEffect(() => {
    setLoading(true);
    axios
      .get(
        `https://api.terminals.dev/vault-set/balance?vaultSet=${tokenSelected?.path_id}&includeTotalUSD=true&email=${tokenSelected?.path_created_by}`
      )
      .then(({ data }) => {
        setCoinList(data.wallet);
      })
      .finally(() => setLoading(false));
  }, [tokenSelected?.path_id, tokenSelected?.path_created_by]);
  return (
    <div className={classNames.virtualProspectusVaults}>
      <div className={classNames.assetList}>
        <div className={classNames.head}>
          <div className={classNames.asset}>Asset</div>
          <div className={classNames.balance}>Balance</div>
          <div className={classNames.ch24}>24Hr</div>
          <div className={classNames.usd}>USD</div>
        </div>
        <div className={classNames.coinList}>
          {loading
            ? Array(8)
                .fill("")
                .map((_, i) => (
                  <div className={`${classNames.coinItem} ${classNames.true}`}>
                    <div className={classNames.asset}>
                      <Skeleton circle className={classNames.coin} />
                      <div className={classNames.nameDetail}>
                        <Skeleton className={classNames.name} width={1050} />
                        <Skeleton className={classNames.symbol} width={500} />
                      </div>
                    </div>
                  </div>
                ))
            : coinList
                ?.filter(
                  (coin) =>
                    coin?.ter_coin?.coin_code
                      .toLowerCase()
                      .includes(search.toLowerCase()) ||
                    coin?.ter_coin?.coin_name
                      .toLowerCase()
                      .includes(search.toLowerCase()) ||
                    !isVault
                )
                .map((coin) => (
                  <div
                    className={`${classNames.coinItem} ${
                      classNames[(!coinSelected && true).toString()]
                    } ${coin === coinSelected && classNames.select}`}
                    key={coin.id}
                    onClick={() => setCoinSelected(coin)}
                  >
                    <div className={classNames.asset}>
                      <img
                        src={coin?.ter_coin?.image}
                        alt=""
                        className={classNames.coin}
                      />
                      <div className={classNames.nameDetail}>
                        <div className={classNames.name}>
                          {coin?.ter_coin?.coin_name}
                        </div>
                        <div className={classNames.symbol}>
                          {coin?.ter_coin?.coin_code}
                        </div>
                      </div>
                    </div>
                    <div className={classNames.balance}>
                      {FormatCurrency(coin.balance, coin?.ter_coin?.coin_code)}
                    </div>
                    <div className={classNames.ch24}>{FormatCurrency()}%</div>
                    <div className={classNames.usd}>
                      {FormatCurrency(coin.usdValue, coin?.ter_coin?.coin_code)}
                    </div>
                  </div>
                ))}
        </div>
      </div>
      <VaultsSidebar
        coinSelected={coinSelected}
        search={search}
        setSearch={setSearch}
        isVault={isVault}
        setIsVault={setIsVault}
        tokenSelected={tokenSelected}
      />
    </div>
  );
}

function VaultsSidebar({
  coinSelected,
  search,
  setSearch,
  isVault,
  setIsVault,
  tokenSelected,
}: any) {
  const { pathId } = useParams();
  const { data: coinList } = useCoinList();
  useEffect(() => {
    if (coinSelected) setIsVault(false);
  }, [coinSelected, setIsVault]);

  const coiOb = useMemo(
    () =>
      coinList?.filter(
        (coin: any) => coin.coinSymbol === coinSelected?.ter_coin?.coin_code
      )[0],
    [coinList, coinSelected?.ter_coin?.coin_code]
  );

  const [loading, setLoading] = useState(false);
  const [txnList, setTxnList] = useState<any[]>([]);
  useEffect(() => {
    setLoading(true);
    axios
      .get(
        `https://api.terminals.dev/vault-set/transactions?vaultSet=${pathId}&asset=${coinSelected?.ter_coin?.coin_code}&page=1&limit=20`,
        {
          headers: {
            email: tokenSelected?.path_created_by,
          },
        }
      )
      .then(({ data }) => {
        setTxnList(data?.items);
      })
      .finally(() => setLoading(false));
  }, [
    coinSelected?.ter_coin?.coin_code,
    pathId,
    tokenSelected?.path_created_by,
  ]);
  return (
    <div className={classNames.vaultsSidebar}>
      <div className={classNames.header}>
        <input
          type="text"
          className={classNames.serchInput}
          placeholder={isVault ? "Search Vaults" : "Search Transactions"}
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
        <div
          className={`${classNames.btnBox} ${classNames[isVault.toString()]}`}
          onClick={() => setIsVault(true)}
        >
          <img src={wallet} alt="" />
        </div>
        <div
          className={`${classNames.btnBox} ${
            classNames[(!isVault).toString()]
          }`}
          onClick={() => {
            if (coinSelected) setIsVault(false);
          }}
        >
          <img src={calculator} alt="" />
        </div>
      </div>
      {isVault ? (
        <div className={classNames.notSelected}>
          <div className={classNames.transactionTitle}>Transactions</div>
          <div className={classNames.transactionSubTitle}>
            Select Vault To See Transactions
          </div>
        </div>
      ) : (
        <div className={classNames.transactionList}>
          {loading
            ? ""
            : txnList
                ?.filter((txn) =>
                  txn?.tx_message?.toLowerCase().includes(search?.toLowerCase())
                )
                .map((txn) => (
                  <div className={classNames.txnItem}>
                    <div className={classNames.timeAmt}>
                      <span>
                        {moment(parseInt(txn.updated_at)).format("hh:mm A z")}
                      </span>
                      <span className={txn.tx_type}>
                        {txn.tx_type === "credit" ? "+" : "-"}
                        {FormatCurrency(
                          txn.amount,
                          coinSelected?.ter_coin?.coin_code
                        )}
                      </span>
                    </div>
                    <div className={classNames.dateUsd}>
                      <span>
                        {moment(parseInt(txn.updated_at)).format(
                          "dddd MMMM Do YYYY"
                        )}
                      </span>
                      <span>
                        ${FormatCurrency(txn.amount * coiOb?.usd_price)}
                      </span>
                    </div>
                  </div>
                ))}
        </div>
      )}
    </div>
  );
}

export default VirtualProspectusVaults;
