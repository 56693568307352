import { useCallback, useEffect, useRef, useState } from "react";
import classNames from "./landingPage.module.scss";
import NewsCarousel from "../../components/NewsCarousel";
import { DISP_CURRENCY, DISP_CURRENCY_SYM } from "../../configs";
import MainLayout from "../../layouts/MainLayout";
import MarketTableCrypto from "../../components/MarketTableCrypto";
import { ReactComponent as SearchIcon } from "../../static/images/clipIcons/searchIcon.svg";

function LandingPage(): JSX.Element {
  const ref = useRef<any>();
  const [active, setActive] = useState(false);
  const [search, setSearch] = useState("");

  const scrollHandle = useCallback((event) => {
    setActive(
      Boolean(
        event?.path[0].childNodes[0].childNodes[2].childNodes[0]?.offsetTop -
          event?.path[0]?.scrollTop ===
          100
      )
    );
  }, []);

  useEffect(() => {
    ref.current.addEventListener("scroll", scrollHandle);
    return () => {
      try {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        ref?.current?.removeEventListener("scroll", scrollHandle);
      } catch (error) {}
    };
  }, [scrollHandle]);

  return (
    <MainLayout
      className={classNames.landingPage}
      searchComponent={
        <label className={classNames.searchWrap}>
          <SearchIcon className={classNames.icon} />
          <input
            type="text"
            placeholder="Search For Trading Pairs..."
            className={classNames.search}
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        </label>
      }
    >
      <div className={classNames.pageWrap} ref={ref}>
        <div className={classNames.pageContent}>
          <NewsCarousel />
          <MarketTableCrypto
            displayCurrency={DISP_CURRENCY}
            active={active}
            search={search}
            currencySymbol={DISP_CURRENCY_SYM}
          />
        </div>
      </div>
    </MainLayout>
  );
}

export default LandingPage;
