import { useCallback, useEffect, useRef, useState } from "react";
import classNames from "./contactus.module.scss";
import { Link, useNavigate } from "react-router-dom";
import MainLayout from "../../layouts/MainLayout";
import { each } from "immer/dist/internal";

const allCards = [
    {
        key:0,
        title:'Broker Related',
        description:'Are You interested in the CryptoBroker program? Lets talk about it.',
        link:'/brokerrelated',
    },
    {
        key:1,
        title:'Fund Manager Related',
        description:'Are You interested in the FundManager program? Lets talk about it.',
        link:'/fundmangerrelated',
    },
    {
        key:2,
        title:'Partnerships',
        description:'Are you looking to partner with IndianOTC for your business? Lets talk about it.',
        link:'/partnerships',
    },
    {
      key:3,
      title:'Investor Relations',
      description:"Are you interested in owning India’s next big cryptocurrency exchange? Lets talk about it.",
      link:'/investorrelations',
    },
    {
      key:4,
      title:'Career Opportunities',
      description:"Are you interested in working for India’s next big cryptocurrency exchange? Get in touch today.",
      link:'/careeropportunities',
    },
    {
      key:5,
      title:'Other Inquiries',
      description:'Have general questions or feedback? We would love to hear from you.',
      link:'/otherinquiries',
    },


]

function ContactUs(): JSX.Element {
  let navigate = useNavigate();
  const handleselect = (each: any) => {
    navigate(`/about/contact${each.link}`)
  }

  return (
    <MainLayout
      className={classNames.landingPage}
    >
      <div className={classNames.pageWrap}>
        <div className={classNames.contactMaster}>
          <div className={classNames.titlesection}>
            <p className={classNames.mainTitle}>Select Of The Options</p>
            <p className={classNames.mainDesc}>If you need assistance with an issue regarding your account. You can access our dedicated customer support chat inside the application. This page is reserved for other types of inquiries.</p>
          </div>
          <div className={classNames.querysection}>
            {allCards.map((each, id) => {
              return(
                <div className={classNames.eachcard} key={id} onClick={() => handleselect(each)}>
                    <p className={classNames.queryTitle}>{each.title}</p>
                    <p className={classNames.queryDesc}>{each.description}</p>
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </MainLayout>
  );
}

export default ContactUs;
