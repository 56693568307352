import { useEffect, useState } from "react";
import axios from "axios";
import Skeleton from "react-loading-skeleton";
import classNames from "./staticsCard.module.scss";
import supplyIcon from "../../../static/images/icons/supply.svg";
import walletIcon from "../../../static/images/icons/wallet.svg";
import sentimentIcon from "../../../static/images/icons/sentiment.svg";
import doubleArrow from "../../../static/images/icons/doubleArrow.svg";
import { FormatCurrency, FormatNumber } from "../../../utils/FunctionTools";
import DonutChart from "../../DonutChart/Index";
import { useCoinList } from "../../../queryHooks";

function StaticsCard({ card, i, next, setModalOutSpace, tokenSelected }: any) {
  const { data: coinList = [] } = useCoinList();
  const [list, setList] = useState([]);
  const [usdTotal, setUsdTotal] = useState(0);
  const [segment, setSegment] = useState<null | number>(0);
  const [donutData, setDonutData] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    switch (card?.key) {
      case "1":
        setLoading(true);
        axios
          .get(
            `https://api.terminals.dev/vault-set/balance?vaultSet=${tokenSelected?.path_id}&includeTotalUSD=true&email=${tokenSelected?.path_created_by}`
          )
          .then(({ data }) => {
            setList(data.wallet);
            setUsdTotal(data.totalUSD);
            let arr: any[] = [];
            data.wallet.forEach((coin: any) => {
              arr.push({
                value: parseFloat(coin.usdValue),
                name: coin?.ter_coin?.coin_name,
                coin: coin?.ter_coin?.coin_code,
                color: coin?.ter_coin?.color_code,
              });
            });
            setDonutData(arr);
          })
          .finally(() => setLoading(false));

        break;

      default:
        break;
    }
  }, [card?.key, tokenSelected?.path_created_by, tokenSelected?.path_id]);
  const [tab, setTab] = useState("Holdings");

  const [cardCoin, setCardCoin] = useState<any>();
  useEffect(() => {
    setCardCoin(
      coinList.filter(
        (coin: any) => coin.coinSymbol === tokenSelected?.asset
      )[0]
    );
  }, [coinList, tokenSelected?.asset]);

  function getContent() {
    switch (tab) {
      case "Holdings":
        return (
          <>
            <div className={classNames.donutNcard}>
              <div className={classNames.donutWrap}>
                {loading ? (
                  <Skeleton
                    className={classNames.donutSvg}
                    circle
                    width={250}
                    height={250}
                  />
                ) : (
                  <DonutChart
                    className={classNames.donutSvg}
                    pieData={donutData}
                    onMouseOver={(segmentIndex: number) => {
                      setSegment(segmentIndex);
                    }}
                    onMouseOut={() => {
                      setSegment(null);
                    }}
                    segment={segment}
                  />
                )}
                <div
                  className={`${classNames.centreDonut} ${
                    classNames[String(Boolean(segment || segment === 0))]
                  }`}
                >
                  {FormatNumber(
                    ((donutData && donutData[segment!]?.value) / usdTotal) *
                      100,
                    2
                  )}
                  %
                </div>
              </div>
              <div className={classNames.donutCards}>
                <div className={classNames.cardHead}>Holdings Breakdown</div>
                {loading
                  ? Array(5)
                      .fill("")
                      .map(() => (
                        <div className={classNames.detailCard}>
                          <Skeleton className={classNames.sold} width={120} />
                          <Skeleton className={classNames.value} width={120} />
                          <Skeleton className={classNames.coin} circle />
                        </div>
                      ))
                  : list.map((coin, i) => (
                      <CoinCard
                        segment={segment}
                        coin={coin}
                        setSegment={setSegment}
                        i={i}
                        coinSelected={cardCoin}
                        setModalOutSpace={setModalOutSpace}
                      />
                    ))}
              </div>
            </div>
            <div className={classNames.footer}>
              <div className={classNames.total}>
                <span>Total Holdings</span>
                <span>
                  {cardCoin?.symbol?.length < 3 ? cardCoin?.symbol : ""}
                  {FormatCurrency(
                    usdTotal / cardCoin?.usd_price,
                    cardCoin?.symbol
                  )}{" "}
                  {cardCoin?.symbol?.length < 3 ? "" : cardCoin?.symbol}
                </span>
              </div>
              <div className={classNames.coin}>
                <img src={cardCoin?.coinImage} alt="" />
              </div>
            </div>
          </>
        );
      case "Returns":
        return (
          <>
            <div className={classNames.returns}>
              <div className={classNames.title}>
                Against USDT
                <div className={classNames.date}>
                  Jan 1st 2021 - Feb 23rd 2021
                </div>
              </div>
              <div
                className={classNames.returnCard}
                style={{ borderLeftColor: "#00BD9A" }}
              >
                <div className={classNames.percent}>+12.34%</div>
                <div className={classNames.value}>+2,3512.34</div>
              </div>
              <div className={classNames.title}>
                Against USD
                <div className={classNames.date}>
                  Jan 1st 2021 - Feb 23rd 2021
                </div>
              </div>
              <div
                className={classNames.returnCard}
                style={{ borderLeftColor: "#DC3027" }}
              >
                <div className={classNames.percent}>+12.34%</div>
                <div className={classNames.value}>+2,3512.34</div>
              </div>
            </div>
          </>
        );
      case "Sentiment":
        return (
          <>
            <div className={classNames.sentiment}>
              <div className={classNames.sentimentWrap}>
                <div className={classNames.sentiCard}>
                  <div className={classNames.value}>
                    <div className={classNames.text}>13.35%</div>
                    <div className={classNames.subText}>
                      Of Investors Disapprove
                    </div>
                  </div>
                  <div className={classNames.moreData}>
                    <span>More Data</span>
                    <img src={doubleArrow} alt="" />
                  </div>
                </div>
                <div className={`${classNames.btnSenti} ${classNames.red}`}>
                  Critique
                </div>
              </div>
              <div className={classNames.sentimentWrap}>
                <div className={classNames.sentiCard}>
                  <div className={classNames.value}>
                    <div className={classNames.text}>76.65%</div>
                    <div className={classNames.subText}>
                      Of Investors Approve
                    </div>
                  </div>
                  <div className={classNames.moreData}>
                    <span>More Data</span>
                    <img src={doubleArrow} alt="" />
                  </div>
                </div>
                <div className={`${classNames.btnSenti} ${classNames.green}`}>
                  Applaud
                </div>
              </div>
            </div>
          </>
        );

      default:
        break;
    }
  }

  return (
    <div
      className={`${classNames.content} ${classNames["i" + i]}`}
      onClick={() => next(i)}
    >
      <div className={classNames.stats}>
        <div className={classNames.title}>{card.title}</div>
        <div className={classNames.date}>{card.date}</div>
      </div>
      <div className={classNames.supplyList}>
        <div
          className={`${classNames.supply} ${
            classNames[(tab === "Holdings").toString()]
          }`}
          onClick={() => {
            setTab("Holdings");
            setSegment(null);
          }}
        >
          <img src={walletIcon} alt="" />
          <span>Holdings</span>
        </div>
        <div
          className={`${classNames.supply} ${
            classNames[(tab === "Returns").toString()]
          }`}
          onClick={() => setTab("Returns")}
        >
          <img src={supplyIcon} alt="" />
          <span>Returns</span>
        </div>
        <div
          className={`${classNames.supply} ${
            classNames[(tab === "Sentiment").toString()]
          }`}
          onClick={() => setTab("Sentiment")}
        >
          <img src={sentimentIcon} alt="" />
          <span>Sentiment</span>
        </div>
      </div>
      {getContent()}
    </div>
  );
}

function CoinCard({ segment, coin, setSegment, i, coinSelected }: any) {
  const [cardCoin, setCardCoin] = useState<any>();
  useEffect(() => {
    setCardCoin(coinSelected);
  }, [coinSelected]);
  return (
    <>
      <div
        className={`${classNames.detailCard} ${
          classNames[((!segment && segment !== 0) || segment === i).toString()]
        }`}
        style={{ borderLeftColor: coin?.ter_coin.color_code }}
        onClick={() => setSegment(i)}
      >
        <div className={classNames.sold}>{coin?.ter_coin.coin_name}</div>

        {cardCoin?.default ? (
          <div className={classNames.value}>
            {FormatCurrency(coin.balance, coin?.ter_coin?.coin_code)}
            {coin?.ter_coin?.coin_code}
          </div>
        ) : (
          <div className={classNames.value}>
            {cardCoin?.symbol.length < 3 ? cardCoin?.symbol : ""}
            {FormatCurrency(
              coin.usdValue / cardCoin?.usd_price,
              cardCoin?.ter_coin?.symbol
            )}{" "}
            {cardCoin?.symbol.length < 3 ? "" : cardCoin?.symbol}
          </div>
        )}
        <img
          src={coin?.ter_coin?.image}
          alt=""
          className={classNames.coin}
          // onClick={() => setChangeCoin(true)}
        />
      </div>
    </>
  );
}

export default StaticsCard;
