import React, { useCallback, useEffect, useRef, useState }  from 'react';
import { Link, useNavigate, useLocation } from "react-router-dom";
import classNames from "./selectoptions.module.scss";

const allCards = [
    {
        key:0,
        title:'Broker Related',
        description:'Are You interested in the CryptoBroker program? Lets talk about it.',
        link:'/brokerrelated',
    },
    {
        key:1,
        title:'Fund Manager Related',
        description:'Are You interested in the FundManager program? Lets talk about it.',
        link:'/fundmangerrelated',
    },
    {
        key:2,
        title:'Partnerships',
        description:'Are you looking to partner with IndianOTC for your business? Lets talk about it.',
        link:'/partnerships',
    },
    {
      key:3,
      title:'Investor Relations',
      description:"Are you interested in owning India’s next big cryptocurrency exchange? Lets talk about it.",
      link:'/investorrelations',
    },
    {
      key:4,
      title:'Career Opportunities',
      description:"Are you interested in working for India’s next big cryptocurrency exchange? Get in touch today.",
      link:'/careeropportunities',
    },
    {
      key:5,
      title:'Other Inquiries',
      description:'Have general questions or feedback? We would love to hear from you.',
      link:'/otherinquiries',
    },
]

function SelectOptions() {
    let navigate = useNavigate();
    let location = useLocation();

    const handleselect = (each:any) => {
        localStorage.setItem('selectquery', `/about/contact${each.link}`)
        navigate(`/about/contact${each.link}`)
    }

  return (
    <div className={classNames.optionsMaster}>
        <div className={classNames.headersection}>
            <p className={classNames.maintitle}>Select Of The Options</p>
            <p className={classNames.maindesc}>If you need assistance with an issue regarding your account. You can access our dedicated customer support chat inside the application. This page is reserved for other types of inquiries.</p>
        </div>
        <div className={classNames.cardsSection}>
            {allCards.map((each, id) => {
              return(
                <div className={ location.pathname === `/about/contact${each.link}` ?
                `${classNames.eachcard} ${classNames.selectactive}`: classNames.eachcard} key={id} onClick={() => handleselect(each)}>
                    <p className={classNames.queryTitle}>{each.title}</p>
                    <p className={classNames.queryDesc}>{each.description}</p>
                </div>
              )
            })}
        </div>
    </div>
  )
}

export default SelectOptions