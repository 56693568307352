import classNames from "./topNavbarTerminalSubMenu.module.scss";
import { useLocation } from "react-router-dom";
import wealth from '../../static/images/sidebarIcons/wealth/wealth.svg';
import tellers from '../../static/images/sidebarIcons/wealth/tellers.svg';
import coldstorage from '../../static/images/sidebarIcons/wealth/coldstorage.svg';
import bharattrust from '../../static/images/sidebarIcons/wealth/bharattrust.svg';



const SIDEBAR_LIST = [
  {
    label: "Wealth",
    icon: wealth,
    link: "",
  },
  {
    label: "Tellers",
    icon: tellers,
    link: "",
  },
  {
    label: "Cold Storage",
    icon: coldstorage,
    link: "",
  },
  {
    label: "BharatTrust",
    icon: bharattrust,
    link: "",
  },
  // {
  //   label: "",
  //   icon: '',
  //   link: "",
  // },
];

const ACTIONS_LIST = [
  {
    title: "What Is Wealth?",
    subTitle: "Wealth",
  },
  {
    title: "Wealth’s Requirements",
    subTitle: "Wealth",
  },
  {
    title: "What Is Cold Storage?",
    subTitle: "Cold Storage",
  },
  {
    title: "Understanding P2P",
    subTitle: "Wealth",
  },
  {
    title: "What Is A Teller?",
    subTitle: "Wealth",  
  },
  {
    title: "",
    subTitle: "",  
  },
  {
    title: "Can I Convert Cash?",
    subTitle: "Wealth",
  },
  {
    title: "What Is BharatTrust?",
    subTitle: "BharatTrust",
  },
  {
    title: "",
    subTitle: "",  
  },
];

function TopNavbarTerminalSubMenu({
  openTab,
  setOpenTab,
}: {
  openTab: boolean;
  setOpenTab: any;
}) {
  const { pathname } = useLocation();
  return (
    <div
      className={`${classNames.topNavbarTerminalSubMenuWrap} ${
        classNames[openTab.toString()]
      }`}
    >
      {openTab && (
        <div className={classNames.overlay} onClick={() => setOpenTab("")} />
      )}
      <div className={classNames.topNavbarTerminalSubMenu}>
      <div className={classNames.subMenuList}>
          {SIDEBAR_LIST.map((menu, i) => (
            <div
              key={menu.label}
              onClick={() => {
                // navigate(`/trading${menu.link}`);
              }}
              className={` ${
                classNames.sideMenu
              }`}
            >
              <img src={menu.icon} alt="" />
              <span>{menu.label}</span>
            </div>
          ))}
        </div>
        <div className={classNames.tabDetailContent}>
          <div className={classNames.titlle}>Wealth FAQ’s</div>
          <div className={classNames.subtitle}>
          Speak To An Wealth Specialist By Clicking Here <strong>Here</strong>
          </div>
          <div className={classNames.actionList}>
            {ACTIONS_LIST.map((action: any, i) => (
              <div className={classNames.action} key={i}>
                <div className={classNames.icon} style={{display: action.title === '' ? 'none' : ''}}>  </div>
                <div className={classNames.detail}>
                  <div className={classNames.actName}>{action.title}</div>
                  <div className={classNames.actDesc}>{action.subTitle}</div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default TopNavbarTerminalSubMenu;
