import { memo } from "react";
import { motion } from "framer-motion";
import classNames from "./loadingAnimation.module.scss";

const icon = {
  hidden: {
    opacity: 0,
    pathLength: 0,
    fill: "#ffffff00",
  },
  visible: {
    opacity: 1,
    pathLength: 1,
    fill: "#ffffffff",
  },
};

function LoadingAnimation({
  className,
  classNameSvg,
}: {
  className?: string;
  classNameSvg?: string;
}) {
  return (
    <div className={`${classNames.loadingAnimation} ${className}`}>
      <motion.svg
        className={`${classNames.logo} ${classNameSvg}`}
        viewBox="0 0 37 37"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M32.9651 18.1819C32.9651 26.3466 26.3463 32.9654 18.1815 32.9654C10.0169 32.9654 3.39802 26.3466 3.39802 18.1819C3.39802 10.017 10.0169 3.3981 18.1815 3.3981C26.3463 3.3981 32.9651 10.017 32.9651 18.1819Z"
          fill="#F2A900"
        />
        <path
          d="M18.1815 0.401684C8.37767 0.401684 0.401672 8.37779 0.401672 18.1818C0.401672 27.9856 8.37767 35.9616 18.1815 35.9616C27.9853 35.9616 35.9614 27.9856 35.9614 18.1818C35.9614 8.37779 27.9853 0.401684 18.1815 0.401684ZM18.1815 3.39805C26.3463 3.39805 32.9651 10.017 32.9651 18.1818C32.9651 26.3466 26.3463 32.9653 18.1815 32.9653C10.0169 32.9653 3.39801 26.3466 3.39801 18.1818C3.39801 10.017 10.0169 3.39805 18.1815 3.39805Z"
          fill="#D29300"
        />
        <path
          d="M18.1817 1.19522C8.81547 1.19522 1.19538 8.81545 1.19538 18.1819C1.19538 27.5483 8.81547 35.1683 18.1817 35.1683C27.5483 35.1683 35.1686 27.5483 35.1686 18.1819C35.1686 8.81545 27.5483 1.19522 18.1817 1.19522ZM18.1817 36.3638C8.15633 36.3638 0 28.2073 0 18.1819C0 8.15621 8.15633 5.72205e-05 18.1817 5.72205e-05C28.2073 5.72205e-05 36.3637 8.15621 36.3637 18.1819C36.3637 28.2073 28.2073 36.3638 18.1817 36.3638Z"
          fill="#5F6163"
        />
        <motion.path
          d="M19.7035 8.16503C19.7035 9.11283 18.9352 9.88115 17.9873 9.88115C17.0395 9.88115 16.2712 9.11283 16.2712 8.16503C16.2712 7.21722 17.0395 6.44888 17.9873 6.44888C18.9352 6.44888 19.7035 7.21722 19.7035 8.16503Z"
          fill="white"
          stroke="#D29300"
          strokeWidth={0.2}
          variants={icon}
          initial="hidden"
          animate="visible"
          transition={{
            default: { duration: 2, ease: "easeInOut", repeat: Infinity },
            fill: { duration: 2, ease: [1, 0, 0.8, 1], repeat: Infinity },
          }}
        />
        <motion.path
          d="M13.8129 21.7996C13.8129 22.282 13.9766 22.75 14.2772 23.1272L23.5876 30.483L17.4166 22.9389L15.1103 20.1483C14.3512 20.3353 13.8129 21.0139 13.8129 21.7996Z"
          fill="white"
          stroke="#D29300"
          strokeWidth={0.2}
          variants={icon}
          initial="hidden"
          animate="visible"
          transition={{
            default: { duration: 2, ease: "easeInOut", repeat: Infinity },
            fill: { duration: 2, ease: [1, 0, 0.8, 1], repeat: Infinity },
          }}
        />
        <motion.path
          d="M21.2752 16.7334L24.8368 14.9266H21.2639C21.2639 14.9266 21.393 15.6174 21.2752 16.7334Z"
          fill="white"
          stroke="#D29300"
          strokeWidth={0.2}
          variants={icon}
          initial="hidden"
          animate="visible"
          transition={{
            default: { duration: 2, ease: "easeInOut", repeat: Infinity },
            fill: { duration: 2, ease: [1, 0, 0.8, 1], repeat: Infinity },
          }}
        />
        <motion.path
          d="M24.8861 10.4719V10.4425H10.7672L14.2216 12.7201C14.7879 12.7372 15.2758 12.7863 15.6828 12.8696C16.1828 12.9726 16.6217 13.1491 16.9968 13.3991C17.4258 13.6859 17.7446 14.0538 17.948 14.5049C18.0093 14.6396 18.0608 14.7795 18.1024 14.9266H15.489C14.9556 15.6871 15.4995 16.7334 16.4287 16.7334H18.2226C18.1834 17.1526 18.1049 17.535 17.9824 17.8783C17.7985 18.3956 17.5166 18.8442 17.1316 19.2193C16.7026 19.6385 16.1828 19.9156 15.5748 20.0504L15.5624 20.0532C15.5486 20.0561 15.536 20.0623 15.5223 20.0656L16.8889 21.7127C18.1687 21.2395 19.1788 20.5088 19.9191 19.5258C20.5076 18.7462 20.8631 17.817 20.9831 16.7334C21.1106 15.6174 20.9709 14.9266 20.9709 14.9266C20.8851 14.2793 20.7012 13.7105 20.4217 13.2251C20.3188 13.0486 20.2084 12.8794 20.0883 12.7201H23.2999L24.8861 10.4719Z"
          fill="white"
          stroke="#D29300"
          strokeWidth={0.2}
          variants={icon}
          initial="hidden"
          animate="visible"
          transition={{
            default: { duration: 2, ease: "easeInOut", repeat: Infinity },
            fill: { duration: 2, ease: [1, 0, 0.8, 1], repeat: Infinity },
          }}
        />
      </motion.svg>
    </div>
  );
}

export default memo(LoadingAnimation);
