import classNames from "./otcFundPage.module.scss";
import MainLayout from "../../layouts/MainLayout";
import bhimIcon from "../../static/images/paymentLogos/bhim.png";
import gPayIcon from "../../static/images/paymentLogos/gPay.png";
import paytmIcon from "../../static/images/paymentLogos/paytm.png";
import phonePeIcon from "../../static/images/paymentLogos/phonePe.png";
import { FormatCurrency } from "../../utils/FunctionTools";
import { DISP_CURRENCY } from "../../configs";

function OTCFundPage(): JSX.Element {
  return (
    <MainLayout className={classNames.oTCFundPage} loggedIn>
      <div className={classNames.pageWrap}>
        <div className={classNames.pageContent}>
          <div className={classNames.subNavBar}>
            <div className={`${classNames.navItem} ${classNames.false}`}>
              NEFT
            </div>
            <div className={`${classNames.navItem} ${classNames.false}`}>
              RTGS
            </div>
            <div className={`${classNames.navItem} ${classNames.false}`}>
              IMPS
            </div>
            <div className={`${classNames.navItem} ${classNames.true}`}>
              UPI
            </div>
            <div className={`${classNames.navItem} ${classNames.false}`}>
              Cards
            </div>
            <div className={`${classNames.navItem} ${classNames.false}`}>
              P2P
            </div>
            <input
              type="text"
              className={classNames.search}
              placeholder="Search Funding Methods"
            />
          </div>
          <div className={classNames.paymentAppTabs}>
            <div className={`${classNames.tabPayment} ${classNames.true}`}>
              <img src={gPayIcon} alt="" className={classNames.img} />
              <span>Google Pay</span>
            </div>
            <div className={`${classNames.tabPayment} ${classNames.false}`}>
              <img src={phonePeIcon} alt="" className={classNames.img} />
              <span>PhonePe</span>
            </div>
            <div className={`${classNames.tabPayment} ${classNames.false}`}>
              <img src={bhimIcon} alt="" className={classNames.img} />
              <span>BHIM</span>
            </div>
            <div className={`${classNames.tabPayment} ${classNames.false}`}>
              <img src={paytmIcon} alt="" className={classNames.img} />
              <span>PayTM</span>
            </div>
          </div>
          <div className={classNames.mainContent}>
            <div className={classNames.sendFundArea}>
              <div className={classNames.label}>You Are Sending</div>
              <input
                type="text"
                className={classNames.input}
                placeholder={`${FormatCurrency(
                  0,
                  DISP_CURRENCY
                )} ${DISP_CURRENCY}`}
              />
              <div className={classNames.label}>You Are Sending</div>
              <input
                type="text"
                className={classNames.input}
                placeholder={`${FormatCurrency(
                  0,
                  DISP_CURRENCY
                )} ${DISP_CURRENCY}`}
              />
              <div className={classNames.btnStart}>Start</div>
            </div>
            <div className={classNames.sidebar}></div>
          </div>
        </div>
      </div>
    </MainLayout>
  );
}

export default OTCFundPage;
