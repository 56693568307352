import classNames from "./otcBuySellPage.module.scss";
import MainLayout from "../../layouts/MainLayout";
import CoinBuyTable from "../../components/CoinBuyTable";

function OTCBuySellPage({ isBuy = false }: { isBuy: boolean }): JSX.Element {
  return (
    <MainLayout className={classNames.otcBuySellPage} loggedIn>
      <div className={classNames.pageWrap}>
        <div className={classNames.pageContent}>
          <div className={classNames.subNavBar}>
            <input
              type="text"
              className={classNames.search}
              placeholder={`Search For The Coin You Want To ${
                isBuy ? "Buy" : "Sell"
              }....`}
            />
          </div>
          <CoinBuyTable isBuy={isBuy} />
        </div>
      </div>
    </MainLayout>
  );
}

export default OTCBuySellPage;
