import { useCallback, useEffect, useRef, useState } from "react";
import classNames from "./partnership.module.scss";
import { Link, useNavigate, useLocation } from "react-router-dom";
import MainLayout from "../../layouts/MainLayout";
import SelectOptions from "../../components/selectoptions";
import tik from '../../static/images/actionIcons/tik.svg'
import { FULL_LOGO } from "../../configs";

const myCards = [
    {
        key:0,
        title:'White-Label',
        status:false,
    },
    {
        key:1,
        title:'API’s',
        status:false,
    },
    {
        key:2,
        title:'Plugins',
        status:false,
    },
    {
      key:3,
      title:'Liquidity',
      status:false,
    },
    {
      key:4,
      title:'Marketing',
      status:false,
    },
    {
      key:5,
      title:'Distribution',
      status:false,
    },
    {
      key:6,
      title:'Other',
      status:false,
    },
]

function PartnerShips() {
  let navigate = useNavigate();
  const [fullname, setfullname] = useState('');
  const [company, setcompany] = useState('');
  const [description, setdescription] = useState('');
  const [email, setemail] = useState('');
  const [phoneno, setphoneno] = useState('');
  const [whatsapp, setwhatsapp] = useState('');
  const [selectedCards, setSelectedCards] =  useState([])
  const [ submit, setsubmit] = useState(false)


  const handleselectbox = (item) => {
    if (selectedCards.find((o) => o === item.title)) {
      setSelectedCards(selectedCards.filter((o) => o !== item.title));
    } else {
      setSelectedCards([...selectedCards, item.title]);
    }
    
  }


  return (
    <MainLayout
      className={classNames.landingPage}
    >
      <div className={classNames.pageWrap}>
        <div className={classNames.patnershipMaster}>
          <div className={classNames.selectoptions}>
            <SelectOptions />
          </div>
          <div className={classNames.abcd}>
          <p className={classNames.pMaintitle}>Partnerships</p>
            <div className={classNames.partnershipSection}>
              <p className={classNames.psubhead}>Enter Your Full Name</p>
              <div className={classNames.ptextbox}>
                <input className={classNames.pinput} style={{opacity : fullname !== ''? 1 : 0.25}} type='text' placeholder='Name....' value={fullname} onChange={e => setfullname(e.target.value)}/>
              </div>
              <p className={classNames.psubhead}>Enter The Name Of Your Company</p>
              <div className={classNames.ptextbox}>
                <input className={classNames.pinput} style={{opacity : company !== ''? 1 : 0.25}} type='text' placeholder='Company....' value={company} onChange={e => setcompany(e.target.value)}/>
              </div>
              <p className={classNames.psubhead}>What Type Of Partnership Are You Interested In?</p>
              <div className={classNames.multiselectbox}>
                {
                  myCards.map((each, id) => {

                    return(
                      
                      <div className={classNames.eachselect}
                      
                      style={{
                        border: selectedCards.find((o) => o === each.title) !== undefined ? '2px solid #F2A900': '0.5px solid #EFEFEF'
                      }}
                      key={each.title}  
                      onClick={() => handleselectbox(each)}>
                        <div style={{display:'flex', justifyContent: 'end', position: 'relative'}}>
                            {selectedCards.find((o) => o === each.title) !== undefined ?
                              <img style={{position:"absolute",
                              margin: "3px 0px 0 0px",
                              padding: "0 10px 0 0"}} src={tik} alt='' />
                            :''
                            }
                          </div>
                        <div>
                          <div className={classNames.cardtitle}>
                          <p>{each.title}</p>
                          </div>
                        </div>
                      </div>
                    
                    )
                  })
                }
              </div>
              <p className={classNames.psubhead}>Explain The Nature Of The Partnership You Had In Mind</p>
              <div className={classNames.ptextbox}>
                <textarea className={classNames.ptextarea}  style={{opacity : description !== ''? 1 : 0.25}} placeholder='Description....' value={description} onChange={e => setdescription(e.target.value)}></textarea>
              </div>
              <p className={classNames.psubhead}>Enter Your Email</p>
              <div className={classNames.ptextbox}>
                <input className={classNames.pinput} style={{opacity : email !== ''? 1 : 0.25}} type='text' placeholder='Email....' value={email} onChange={e => setemail(e.target.value)}/>
              </div>
              <p className={classNames.psubhead}>Enter A Phone Number That Is Best To Call You At</p>
              <div className={classNames.ptextbox}>
                <input className={classNames.pinput} style={{opacity : phoneno !== ''? 1 : 0.25}} type='number' placeholder='Phone Number...' value={phoneno} onChange={e => setphoneno(e.target.value)}/>
              </div>
              <p className={classNames.psubhead}>Enter A WhatsApp Enabled Phone Number</p>
              <div className={classNames.ptextbox}>
                <input className={classNames.pinput} style={{opacity : whatsapp !== ''? 1 : 0.25}} type='number' placeholder='WhatsApp Number...' value={whatsapp} onChange={e => setwhatsapp(e.target.value)}/>
              </div>
              <div className={classNames.finalsubmit} onClick={() => setsubmit(true)}>Submit Inquiry</div>
            </div>
          </div>
        </div>
      </div>
    <div>
    <>
    {submit ?
    <div className={classNames.pagemask} onMouseDown={() => setsubmit(false)}>
      <div className={classNames.finalBox}>
        <div className={classNames.confirmationbox}>
          <div className={classNames.endbox} onMouseDown={(e) => e.stopPropagation()}>
            <div className={classNames.imgbox}>
              <img className={classNames.otclogo} src={FULL_LOGO} alt='' />
            </div>
            <div className={classNames.textbox}>
              <p className={classNames.confirmtext}>Your Inquiry Has Been Submitted Successfully. What Would You Like To Do Next?</p>
            </div>
            <div className={classNames.buttonBox}>
              <div className={classNames.homebtn} onClick={() => navigate('/')}>Go Home</div>
              <div  className={classNames.inquiry} onClick={() => navigate('/about/contact')}>New Inquiry</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    :''
    }
    </>
    </div>
    </MainLayout>
  );
}

export default PartnerShips;
