import React, { memo, useEffect, useRef, useState } from "react";
import useWebSocket from "react-use-websocket";
import { createChart } from "lightweight-charts";
import classNames from "./terminalsChartSection.module.scss";
import axios from "axios";
import { useSelector } from "react-redux";
import { RootState } from "../../app/store";

const TerminalsChartSection: React.FC<{}> = () => {
  const pair = useSelector((state: RootState) => state.terminalsData.pair);
  const chartContainerRef = useRef<any>();
  const chart = useRef<any>();
  const areaSeries = useRef<any>();
  const parent = useRef<any>();
  const resizeObserver = useRef<any>();
  const { lastJsonMessage: priceData } = useWebSocket(
    `wss://stream.binance.com:9443/ws/${pair
      .replace("/", "")
      .toLowerCase()}@trade`,
    {
      shouldReconnect: () => true,
    }
  );

  const resizeListener = () => {
    chart.current.applyOptions({
      width: parent.current.offsetWidth - 1,
      height: parent.current.offsetHeight - 1,
    });
    setTimeout(() => {
      chart.current.timeScale().scrollToRealTime();
    }, 0);
  };

  useEffect(() => {
    window.addEventListener("resize", resizeListener);
    return () => {
      window.removeEventListener("resize", resizeListener);
    };
  }, []);
  useEffect(() => {
    resizeObserver.current = new ResizeObserver((entries) => {
      resizeListener();
    });

    resizeObserver.current.observe(chartContainerRef.current);

    return () => resizeObserver.current.disconnect();
  }, []);

  useEffect(() => {
    chart.current = createChart(chartContainerRef.current, {
      width: chartContainerRef.current.clientWidth,
      height: chartContainerRef.current.clientHeight,
      layout: {
        backgroundColor: "transparent",
        textColor: "#5f6163",
      },
      grid: {
        vertLines: {
          visible: false,
          color: "#334158",
        },
        horzLines: {
          visible: false,
          color: "#334158",
        },
      },
      timeScale: {
        borderVisible: false,
        borderColor: "#485c7b",
        visible: true,
        timeVisible: true,
        secondsVisible: true,
      },
      crosshair: {
        vertLine: {
          visible: false,
          labelVisible: false,
        },
        horzLine: {
          visible: false,
          labelVisible: false,
        },
        mode: 1,
      },
    });

    areaSeries.current = chart.current.addAreaSeries({
      topColor: "rgba(0, 162, 255, 0.481)",
      bottomColor: "#e7e7e7",
      lineColor: "rgb(33, 149, 243)",
      lineStyle: 0,
      lineWidth: 2,
      crosshairMarkerVisible: false,
      priceLineVisible: false,
      lastValueVisible: false,
    });

    return () => {
      if (areaSeries.current) chart?.current?.removeSeries(areaSeries.current);
    };
  }, []);

  const [firstData, setFirstData] = useState([]);
  useEffect(() => {
    areaSeries.current?.setData([]);
    axios
      .get(
        `https://api.binance.com/api/v3/trades?symbol=${pair.replace(
          "/",
          ""
        )}&limit=1000`
      )
      .then((res) => {
        setFirstData(res.data);
      });
  }, [pair]);

  useEffect(() => {
    areaSeries.current?.setData([]);
    firstData.forEach((data: any) => {
      if (data.price && data.time) {
        var dateTimeSec = new Date(data.time);
        var utcDateTimeSec =
          Date.UTC(
            dateTimeSec.getFullYear(),
            dateTimeSec.getMonth(),
            dateTimeSec.getDate(),
            dateTimeSec.getHours(),
            dateTimeSec.getMinutes(),
            dateTimeSec.getSeconds(),
            0
          ) / 1000;
        areaSeries.current.update({
          time: utcDateTimeSec,
          value: data.price,
        });
      }
    });
  }, [firstData]);

  useEffect(() => {
    if (priceData?.s === pair.replace("/", "") && priceData?.e === "trade") {
      const dateTimeSec = new Date(priceData.T);
      const utcDateTimeSec =
        Date.UTC(
          dateTimeSec.getFullYear(),
          dateTimeSec.getMonth(),
          dateTimeSec.getDate(),
          dateTimeSec.getHours(),
          dateTimeSec.getMinutes(),
          dateTimeSec.getSeconds(),
          0
        ) / 1000;
      areaSeries.current.update({
        time: utcDateTimeSec,
        value: priceData.p,
      });
    }
  }, [pair, priceData]);

  return (
    <div className={classNames.chartWrap}>
      <div className={classNames.chartHead}></div>
      <div ref={parent} className={classNames.chartContainer}>
        <div ref={chartContainerRef} className={classNames.chart} />
      </div>
    </div>
  );
};
export default memo(TerminalsChartSection);
