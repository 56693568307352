import React, { memo } from "react";
import classNames from "./founderMoreCarousel.module.scss";

const NEWS_LIST = [
  {
    title: "IndianOTC ShareToken Offering",
    source: "IndianInvestor",
    img: "",
  },
  {
    title: "IndianOTC ShareToken Offering",
    source: "IndianInvestor",
    img: "",
  },
  {
    title: "IndianOTC ShareToken Offering",
    source: "IndianInvestor",
    img: "",
  },
  {
    title: "IndianOTC ShareToken Offering",
    source: "IndianInvestor",
    img: "",
  },
  {
    title: "IndianOTC ShareToken Offering",
    source: "IndianInvestor",
    img: "",
  },
  {
    title: "IndianOTC ShareToken Offering",
    source: "IndianInvestor",
    img: "",
  },
];

const FounderMoreCarousel: React.FC<{ readMore: boolean }> = ({ readMore }) => {
  return (
    <div
      className={`${classNames.founderMoreCarousel} ${
        classNames[readMore.toString()]
      }`}
    >
      <div className={classNames.newsList}>
        {NEWS_LIST.map((news) => (
          <div className={classNames.newsCard}>
            {/* <img src={news.img} alt="" className={classNames.img} /> */}
            <svg
              viewBox="0 0 322 182"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className={classNames.img}
            >
              <rect
                x="-0.25"
                y="0.25"
                width="320.5"
                height="180.5"
                transform="matrix(1 0 0 -1 1 181.5)"
                fill="white"
                stroke="#E5E5E5"
                stroke-width="0.5"
              />
            </svg>
            <div className={classNames.source}>{news.source}</div>
            <div className={classNames.title}>{news.title}</div>
          </div>
        ))}
      </div>
    </div>
  );
};
export default memo(FounderMoreCarousel);
