import { useState, useContext } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";
import classNames from "./topNavbar.module.scss";
import { FULL_LOGO } from "../../configs";
import { ReactComponent as SearchIcon } from "../../static/images/clipIcons/searchIcon.svg";
import caretDown from "../../static/images/clipIcons/caretDown.svg";
import OTCSubMenu from "../TopNavbarOTCSubMenu";
import AboutSubMenu from "../TopNavbarAboutSubMenu";
import TopNavbarTerminalSubMenu from "../TopNavbarTerminalSubMenu";
import TopNavbarAppsSubMenu from "../TopNavbarAppsSubMenu";
import LoginModal from "../LoginModal";
import { useAppSelector } from "../../app/hooks";
import { selectLoginData } from "../../app/loginSlice";
import { useUserDetails } from "../../queryHooks";
import raindropIcon from "../../static/images/clipIcons/raindropIcon.svg";
import raindropsLogo from "../../static/images/clipIcons/raindropsLogo.svg";
import TopNavbarFundsSubMenu from "../TopNavbarFundsSubMenu";
import TopNavbarIIMSubMenu from "../TopNavbarIIMSubMenu";
import Carer from './commonNav';
import { MainContext } from "../../components/context/context";

function TopNavbar({ searchComponent }: { searchComponent?: JSX.Element }) {
  const {selectnav, setselectnav} = useContext(MainContext);
  const navigate = useNavigate();
  const { email } = useAppSelector(selectLoginData);
  const { data: userData } = useUserDetails(email);
  const { pathname } = useLocation();
  const [loginModalOpen, setLoginModalOpen] = useState(false);
  const [openTab, setOpenTab] = useState<string>("");
  const [navOpen, setNavOpen] = useState(false);
  const [loginedPath, setLoginedPath] = useState("/trading/vaults");
  const [rainHover, setRainHover] = useState(false);
  return (
    <>
      <nav key="2" className={classNames.topNavbar}
      style={{zIndex: selectnav ? 0 : 2}}>
        <div className={classNames.navBar}>
          <div
            onClick={() => setNavOpen(!navOpen)}
            className={`${classNames.hamburger} ${
              classNames.hamburgerSqueeze
            } ${navOpen ? classNames.isActive : ""}`}
          >
            <span className={classNames.hamburgerBox}>
              <span className={classNames.hamburgerInner}></span>
            </span>
          </div>
          <Link to="/about/home" className={classNames.logoWrap}>
            <img src={FULL_LOGO} alt="" className={classNames.logo} />
          </Link>
          {searchComponent || (
            <label className={classNames.searchWrap}>
              <SearchIcon className={classNames.icon} />
              <input
                type="text"
                placeholder="Search For Crypto...."
                className={classNames.search}
              />
            </label>
          )}
          <AnimatePresence>
            <div className={classNames.menu}>
              <div
                className={`${classNames.menuItem} ${
                  classNames[/\/about*/i.test(pathname).toString()]
                }`}
                onClick={() => {
                  if (openTab === "About") setOpenTab("");
                  else setOpenTab("About");
                }}
              >
                <span>
                  About
                  {/\/about*/i.test(pathname) && (
                    <motion.div className={classNames.bar} layoutId="bar" />
                  )}
                </span>
              </div>
              <div
                className={`${classNames.menuItem} ${
                  classNames[/\/trading*/i.test(pathname).toString()]
                }`}
                onClick={() => {
                  if (openTab === "OTC") setOpenTab("");
                  else setOpenTab("OTC");
                }}
              >
                <span>
                Trading
                  {/\/trading*/i.test(pathname) && (
                    <motion.div className={classNames.bar} layoutId="bar" />
                  )}
                </span>
              </div>
              <div
                className={`${classNames.menuItem} ${
                  classNames[/^\/iim*/i.test(pathname).toString()]
                }`}
                onClick={() => {
                  if (openTab === "IIM") setOpenTab("");
                  else setOpenTab("IIM");
                }}
              >
                <span>Brokers
                  {/^\/iim*/i.test(pathname) && (
                    <motion.div className={classNames.bar} layoutId="bar" />
                  )}
                </span>
              </div>
              <div
                className={`${classNames.menuItem} ${
                  classNames[/^\/funds*/i.test(pathname).toString()]
                }`}
                onClick={() => {
                  if (openTab === "Funds") setOpenTab("");
                  else setOpenTab("Funds");
                }}
              >
                <span>
                  Funds
                  {/^\/funds*/i.test(pathname) && (
                    <motion.div className={classNames.bar} layoutId="bar" />
                  )}
                </span>
              </div>
              <div
                className={`${classNames.menuItem} ${
                  classNames[/\/wealth*/i.test(pathname).toString()]
                }`}
                onClick={() => {
                  if (openTab === "wealth") setOpenTab("");
                  else setOpenTab("wealth");
                }}
              >
                <span>
                  Wealth
                  {/\/wealth*/i.test(pathname) && (
                    <motion.div className={classNames.bar} layoutId="bar" />
                  )}
                </span>
              </div>
              <div
                className={`${classNames.menuItem} ${
                  classNames[/\/login*/i.test(pathname).toString()]
                }`}
                onClick={() => window.open("https://app.indianotc.com/", "_blank")}
              >
                <span>
                  Login
                </span>
              </div>
              <div
                className={`${classNames.menuItem} ${
                  classNames[/\/register*/i.test(pathname).toString()]
                }`}
                style={{backgroundColor: '#F2A900', color:'white',
                fontWeight: "700"}}
              >
                <span>
                  Register
                  {/\/register*/i.test(pathname) && (
                    <motion.div className={classNames.bar} layoutId="bar" />
                  )}
                </span>
              </div>
            </div>
          </AnimatePresence>
        </div>
        <AboutSubMenu
          openTab={Boolean(openTab === "About")}
          setOpenTab={setOpenTab}
          setLoginedPath={setLoginedPath}
          setLoginModalOpen={setLoginModalOpen}
        />
        <OTCSubMenu
          openTab={Boolean(openTab === "OTC")}
          setOpenTab={setOpenTab}
          setLoginedPath={setLoginedPath}
          setLoginModalOpen={setLoginModalOpen}
        />
        <TopNavbarTerminalSubMenu
          openTab={Boolean(openTab === "wealth")}
          setOpenTab={setOpenTab}
        />
        <TopNavbarAppsSubMenu
          openTab={Boolean(openTab === "Apps")}
          setOpenTab={setOpenTab}
          setLoginModalOpen={setLoginModalOpen}
        />
        <TopNavbarFundsSubMenu
          openTab={Boolean(openTab === "Funds")}
          setOpenTab={setOpenTab}
        />
        <TopNavbarIIMSubMenu
          openTab={Boolean(openTab === "IIM")}
          setOpenTab={setOpenTab}
          setLoginedPath={setLoginedPath}
          setLoginModalOpen={setLoginModalOpen}
        />
        {loginModalOpen && (
          <LoginModal
            onClose={() => setLoginModalOpen(false)}
            onSuccess={() => {
              setLoginModalOpen(false);
              navigate(loginedPath);
            }}
          />
        )}
      </nav>
    </>
  );
}

export default TopNavbar;
