import { useParams } from "react-router-dom";
import { useMemo } from "react";
import { Link } from "react-router-dom";
import classNames from "./getStartedPage.module.scss";
import getStartedAffiliate from "../../static/images/backgrounds/getStartedAffiliate.jpeg";
import getStartedNoIdea from "../../static/images/backgrounds/getStartedNoIdea.jpeg";
import getStartedPrereg from "../../static/images/backgrounds/getStartedPrereg.jpeg";
import GetStartedAffiliate from "../../components/GetStartedAffiliate";
import GetStartedPrereg from "../../components/GetStartedPrereg";

function GetStartedPage() {
  const { type } = useParams();
  const contentIn = useMemo(() => {
    switch (type) {
      case "affiliate":
        return <GetStartedAffiliate />;
      case "preregistered":
        return <GetStartedPrereg />;
      default:
        return (
          <>
            <div className={classNames.textSide}>
              <div className={classNames.title}>How Did You Hear About Us?</div>
            </div>
            <div className={classNames.contentCards}>
              <Link to="affiliate" className={classNames.card}>
                <img
                  src={getStartedAffiliate}
                  alt=""
                  className={classNames.cardImg}
                />
                <div className={classNames.cardTexts}>
                  <div className={classNames.cardTitle}>
                    I Was Referred By An Affiliate
                  </div>
                  <div className={classNames.cardDesc}>
                    Use this option if you have been introduced to us by a
                    friend or family member who is already enjoying our
                    platform.
                  </div>
                </div>
              </Link>
              <Link to="preregistered" className={classNames.card}>
                <img
                  src={getStartedPrereg}
                  alt=""
                  className={classNames.cardImg}
                />
                <div className={classNames.cardTexts}>
                  <div className={classNames.cardTitle}>
                    I Am Already Pre-Registered
                  </div>
                  <div className={classNames.cardDesc}>
                    Use this option is one of our Affiliates have already
                    pre-registered you and you have recieved the temporary login
                    credentials via email.
                  </div>
                </div>
              </Link>
              <div className={`${classNames.card} ${classNames.dis}`}>
                <img
                  src={getStartedNoIdea}
                  alt=""
                  className={classNames.cardImg}
                />
                <div className={classNames.cardTexts}>
                  <div className={classNames.cardTitle}>I Have No Idea</div>
                  <div className={classNames.cardDesc}>
                    Use this option if you have landed on our website without
                    any referral or affiliate. Don’t worry we got you covered as
                    well..
                  </div>
                </div>
              </div>
            </div>
          </>
        );
    }
  }, [type]);
  return <div className={classNames.getStartedPage}>{contentIn}</div>;
}

export default GetStartedPage;
