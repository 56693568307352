import React, { memo, useRef, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { useMarketCoinsList } from "../../queryHooks";
import { FormatCurrency, FormatNumber } from "../../utils/FunctionTools";
import classNames from "./marketTableCrypto.module.scss";
import angleDown from "../../static/images/clipIcons/angleDown.svg";
import clock from "../../static/images/clipIcons/clock.svg";

const streamOpen = false;
function MarketTableCrypto({
  active,
  search,
  displayCurrency,
  currencySymbol,
}: {
  active: boolean;
  search: string;
  displayCurrency: string;
  currencySymbol: string;
}) {
  const headRef = useRef<any>();
  const { data: cryptoList = [], isLoading } = useMarketCoinsList(
    "crypto",
    displayCurrency
  );
  const [filterOpen, setFilterOpen] = useState(false);

  return (
    <div className={classNames.marketTable}>
      <div
        className={`${classNames.header} ${
          classNames[(!streamOpen).toString()]
        } ${classNames[active ? "active" : ""]}`}
        ref={headRef}
      >
        <div className={classNames.assets}>Asset</div>
        <div className={classNames.price}>Price</div>
        <div className={classNames.roiPercent}>24 Hr Change</div>
        <div className={classNames.marCap}>Market Cap</div>
        <div className={classNames.volume}>Trading Volume</div>
        <div className={classNames.supply}>Supply</div>
        <img
          className={`${classNames.btnFilter} ${
            classNames[filterOpen.toString()]
          }`}
          src={angleDown}
          alt=""
          onClick={() => setFilterOpen(!filterOpen)}
        />
      </div>
      {filterOpen && (
        <div className={classNames.filtersWrap}>
          <div className={classNames.filters}>
            <div className={classNames.filter}>
              <img src={clock} alt="" />
              <span>24 Hrs</span>
            </div>
            <div className={classNames.filter}>
              <img
                src={
                  "https://apimachine-s3.s3.us-east-2.amazonaws.com/coinImages/rupee.png"
                }
                alt=""
              />
              <span>{displayCurrency}</span>
            </div>
            <div className={classNames.filter}>
              + <span>Add Filter</span>
            </div>
          </div>
        </div>
      )}
      <div className={classNames.marketsList}>
        {isLoading
          ? Array(8)
              .fill("")
              .map((_, i) => (
                <div
                  className={`${classNames.marketItem}  ${
                    classNames[(!streamOpen).toString()]
                  }`}
                  key={i}
                >
                  <Skeleton width={200} />
                  <Skeleton width={200} />
                  <Skeleton width={200} />
                  <Skeleton width={200} />
                  <Skeleton width={200} />
                </div>
              ))
          : cryptoList
              ?.filter(
                (coin: any) =>
                  coin.coinName.toLowerCase().includes(search.toLowerCase()) ||
                  coin.coinSymbol.toLowerCase().includes(search.toLowerCase())
              )
              ?.map((coin: any, i: number) => (
                <div
                  className={`${classNames.marketItem}  ${
                    classNames[(!streamOpen).toString()]
                  }`}
                  key={coin._id}
                  onClick={() => {}}
                >
                  <div className={classNames.assets}>
                    <img src={coin.coinImage} alt="" />
                    <span className={classNames.name}>{coin.coinName}</span>
                  </div>
                  <div className={classNames.price}>
                    {currencySymbol}
                    {FormatCurrency(
                      coin?.price?.[displayCurrency],
                      displayCurrency
                    )}
                  </div>
                  <div
                    className={`${classNames.roiPercent} ${
                      classNames[(coin?._24hrchange < 0).toString()]
                    }`}
                  >
                    {FormatNumber(coin?._24hrchange, 2)}%
                  </div>
                  <div className={classNames.marCap}>
                    {currencySymbol}
                    {FormatCurrency(coin?.mkt_cap_DC, displayCurrency)}
                  </div>
                  <div className={classNames.volume}>
                    {currencySymbol}
                    {FormatCurrency(coin?.volume24hr_DC, displayCurrency)}
                  </div>
                  <div className={classNames.supply}>
                    {FormatNumber(coin?.total_supply)}&nbsp;
                    {coin.coinSymbol}
                  </div>
                </div>
              ))}
      </div>
    </div>
  );
}

export default memo(MarketTableCrypto);
