import { memo } from "react";
import Skeleton from "react-loading-skeleton";
import { APP_CODE, DISP_CURRENCY_SYM, DISP_CURRENCY } from "../../configs";
import { useCoinsData } from "../../queryHooks";
import { FormatCurrency, FormatNumber } from "../../utils/FunctionTools";
import classNames from "./coinBuyTable.module.scss";

function CoinBuyTable({
  displayCurrency = "INR",
  isBuy = false,
}: {
  displayCurrency?: string;
  isBuy?: boolean;
}) {
  const { data: coinList = [], isLoading } = useCoinsData(
    APP_CODE,
    "crypto",
    displayCurrency
  );

  const symbols: any = {
    USD: "$",
  };
  symbols[DISP_CURRENCY] = DISP_CURRENCY_SYM;

  return (
    <div className={classNames.coinBuyTable}>
      <div className={classNames.tableHeader}>
        <div className={classNames.no}>#</div>
        <div className={classNames.asset}>Asset</div>
        <div className={classNames.price}>Price</div>
        <div className={classNames.ch24}>24Hr %</div>
        <div className={classNames.ch24Dis}>
          24Hr ({symbols[displayCurrency]})
        </div>
        <div className={classNames.marCap}>Marketcap</div>
        <div className={classNames.volume24}>24Hr Trading Volume</div>
      </div>
      {isLoading
        ? Array(6)
            .fill("")
            .map((_, i) => (
              <div
                className={classNames.coinItem}
                key={i}
                style={{
                  padding: "0 40px",
                  justifyContent: "space-between",
                }}
              >
                <Skeleton className={classNames.no} width={20} height={20} />
                <div className={classNames.asset}>
                  <Skeleton width={30} height={30} />
                  <Skeleton className={classNames.coinName} width={120} />
                </div>
                <Skeleton className={classNames.price} width={120} />
                <Skeleton className={classNames.ch24} width={120} />
                <Skeleton className={classNames.ch24Dis} width={120} />
                <Skeleton className={classNames.marCap} width={120} />
                <Skeleton className={classNames.volume24} width={120} />
              </div>
            ))
        : coinList?.map((coin: any, index: number) => (
            <div className={classNames.coinItem} key={coin.coinSymbol}>
              <div className={classNames.no}>{index + 1}</div>
              <div className={classNames.asset}>
                <img src={coin.coinImage} alt="" />
                <div className={classNames.coinName}>{coin.coinName}</div>
              </div>
              <div className={classNames.price}>
                {symbols[displayCurrency]}
                {FormatCurrency(coin.price?.[displayCurrency], displayCurrency)}
              </div>
              <div
                className={`${classNames.ch24} ${
                  classNames[(coin._24hrchange < 0).toString()]
                }`}
              >
                {Math.sign(coin._24hrchange) === -1 ? "-" : "+"}
                {FormatNumber(Math.abs(coin._24hrchange), 2)}%
              </div>
              <div
                className={`${classNames.ch24Dis} ${
                  classNames[(coin._24hrchange_priceDC < 0).toString()]
                }`}
              >
                {Math.sign(coin._24hrchange_priceDC) === -1 ? "-" : "+"}
                {symbols[displayCurrency]}
                {FormatCurrency(
                  Math.abs(coin._24hrchange_priceDC),
                  displayCurrency
                )}
              </div>
              <div className={classNames.marCap}>
                {symbols[displayCurrency]}
                {FormatCurrency(coin.mkt_cap_DC, displayCurrency)}
              </div>
              <div className={classNames.volume24}>
                {symbols[displayCurrency]}
                {FormatCurrency(coin.volume24hr_DC, displayCurrency)}
                <div
                  className={`${classNames.btnTrade} ${
                    classNames[isBuy.toString()]
                  }`}
                >
                  {isBuy ? "Buy" : "Sell"}
                </div>
              </div>
            </div>
          ))}
    </div>
  );
}

export default memo(CoinBuyTable);
