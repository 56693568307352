import { useRef, useEffect, useState } from "react";
import { createChart, PriceScaleMode } from "lightweight-charts";
import moment from "moment";
import classNames from "./vpCandleChart.module.scss";
import marketCharts from "../../../static/images/appIcons/marketCharts.svg";
import { useChartDataByPath } from "../../../queryHooks";
import { GetSortOrder } from "../../../utils/FunctionTools";

const chartType = "Candle Stick";

function VPCandleChart({
  isUsd,
  tokenSelected,
}: {
  isUsd: boolean;
  tokenSelected: any;
}) {
  const chartContainerRef = useRef<any>();
  const chart = useRef<any>();
  const resizeObserver = useRef<any>();
  const candleSeries = useRef<any>();
  const lineSeries = useRef<any>();

  const { data, isLoading } = useChartDataByPath(
    tokenSelected?.path_id,
    tokenSelected?.banker_email
  );

  // Create Chart & Candle Series & Line Chart
  useEffect(() => {
    chart.current = createChart(chartContainerRef.current, {
      width: chartContainerRef.current.clientWidth,
      height: chartContainerRef.current.clientHeight,
      layout: {
        backgroundColor: "#fff",
        textColor: "#464b4e",
        fontFamily: "Montserrat",
      },
      grid: {
        vertLines: {
          visible: false,
          color: "#334158",
        },
        horzLines: {
          visible: false,
          color: "#334158",
        },
      },
      rightPriceScale: {
        visible: false,
      },
      leftPriceScale: {
        visible: true,
        borderVisible: true,
        borderColor: "#485c7b",
        alignLabels: true,
        drawTicks: true,
        mode: PriceScaleMode.Normal,
      },
      timeScale: {
        borderVisible: true,
        borderColor: "#485c7b",
        visible: true,
        timeVisible: false,
      },
      crosshair: {
        vertLine: {
          visible: true,
          labelVisible: true,
        },
        horzLine: {
          visible: true,
          labelVisible: true,
        },
        mode: 1,
      },
    });

    candleSeries.current = chart.current.addCandlestickSeries({
      upColor: "#182542",
      downColor: "#FF2D55",
      borderDownColor: "#FF2D55",
      borderUpColor: "#182542",
      wickDownColor: "#FF2D55",
      wickUpColor: "#182542",
      priceLineVisible: false,
      lastValueVisible: false,
    });

    lineSeries.current = chart.current.addLineSeries({
      color: "#182542",
      lineStyle: 0,
      lineWidth: 1,
      crosshairMarkerVisible: true,
      crosshairMarkerRadius: 3,
      crosshairMarkerBackgroundColor: "#2296f3",
      lineType: 1,
      priceLineVisible: false,
      lastValueVisible: false,
    });
  }, []);

  // Sort & Set Data For Chart
  const [dataForChart, setDataForChart] = useState<any[]>([]);
  useEffect(() => {
    if (!isLoading && data?.items) {
      switch (chartType) {
        // case "Line Chart":
        //   {
        //     let dataForLineChart: any[] = [];
        //     data.items.sort(GetSortOrder("date1")).forEach((item: any) => {
        //       const date = moment(item.date1).format("YYYY-MM-DD");
        //       dataForLineChart.push({
        //         time: date,
        //         value: isUsd
        //           ? item?.holdingsAgainstUSD?.date2Total
        //           : item?.holdingsAgainstDisplayCurrency?.date2Total,
        //       });
        //     });
        //     lineSeries.current?.setData(dataForLineChart);
        //     candleSeries.current?.setData([]);
        //     setDataForChart(dataForLineChart);
        //   }
        //   break;

        default:
          {
            let dataForCandleChart: any[] = [];
            data.items.sort(GetSortOrder("date1")).forEach((item: any) => {
              const date = moment(item.date1).format("YYYY-MM-DD");
              dataForCandleChart.push({
                time: date,
                open: isUsd
                  ? item?.holdingsAgainstUSD?.date1Total
                  : item?.holdingsAgainstDisplayCurrency?.date1Total,
                close: isUsd
                  ? item?.holdingsAgainstUSD?.date2Total
                  : item?.holdingsAgainstDisplayCurrency?.date2Total,
                high: isUsd
                  ? Math.max(
                      item?.holdingsAgainstUSD?.highMarketCap || 0,
                      item?.holdingsAgainstUSD?.date1Total,
                      item?.holdingsAgainstUSD?.date2Total
                    )
                  : Math.max(
                      item?.holdingsAgainstDisplayCurrency?.highMarketCap || 0,
                      item?.holdingsAgainstUSD?.date1Total,
                      item?.holdingsAgainstUSD?.date2Total
                    ),
                low: isUsd
                  ? Math.min(
                      item?.holdingsAgainstUSD?.lowMarketCap ||
                        item?.holdingsAgainstUSD?.date1Total,
                      item?.holdingsAgainstUSD?.date1Total,
                      item?.holdingsAgainstUSD?.date2Total
                    )
                  : Math.min(
                      item?.holdingsAgainstDisplayCurrency?.lowMarketCap ||
                        item?.holdingsAgainstUSD?.date1Total,
                      item?.holdingsAgainstUSD?.date1Total,
                      item?.holdingsAgainstUSD?.date2Total
                    ),
              });
            });
            candleSeries.current?.setData(dataForCandleChart);
            lineSeries.current?.setData([]);
            setDataForChart(dataForCandleChart);
          }
          break;
      }
    }
  }, [data, isLoading, isUsd]);

  // Resize Observer
  useEffect(() => {
    resizeObserver.current = new ResizeObserver((entries) => {
      const { width, height } = entries[0].contentRect;
      chart.current.applyOptions({ width, height });
      setTimeout(() => {
        chart.current.timeScale().fitContent();
      }, 0);
    });

    resizeObserver.current.observe(chartContainerRef.current);

    return () => resizeObserver.current.disconnect();
  }, []);

  useEffect(() => {
    if (dataForChart.length >= 5) {
      chart?.current?.timeScale().setVisibleLogicalRange({
        from: dataForChart.length - 13,
        to: dataForChart.length - 1,
      });
    } else {
      chart?.current?.timeScale().fitContent();
    }
  }, [dataForChart]);

  return (
    <>
      <div className={classNames.vpCandleChart}>
        <div ref={chartContainerRef} className={classNames.chartContainer} />
        <div className={classNames.days} onClick={() => {}}>
          Days
        </div>
        <img
          src={marketCharts}
          alt=""
          className={classNames.logo}
          onClick={() => {
            window.open("https://marketcharts.co/", "_blank")!.focus();
          }}
        />
        <div className={classNames.settingsBtn}>
          <svg
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 192 512"
          >
            <path
              fill="currentColor"
              d="M96 184c39.8 0 72 32.2 72 72s-32.2 72-72 72-72-32.2-72-72 32.2-72 72-72zM24 80c0 39.8 32.2 72 72 72s72-32.2 72-72S135.8 8 96 8 24 40.2 24 80zm0 352c0 39.8 32.2 72 72 72s72-32.2 72-72-32.2-72-72-72-72 32.2-72 72z"
            />
          </svg>
        </div>
      </div>
    </>
  );
}

export default VPCandleChart;
