import React from "react";
import ReactDOM from "react-dom";
import { HelmetProvider } from "react-helmet-async";
import App from "./App";
import { store } from "./app/store";
import { Provider } from "react-redux";
import { saveState } from "./app/browser-storage";
import * as serviceWorker from "./serviceWorker";
import "./static/scss/master.scss";
import "react-loading-skeleton/dist/skeleton.css";
import "react-toastify/dist/ReactToastify.css";
import MainContextProvider from './components/context/context';


store.subscribe(() => {
  saveState(store.getState().loginData);
});

ReactDOM.render(
  <React.StrictMode>
    <MainContextProvider>
    <HelmetProvider>
      <Provider store={store}>
        <App />
      </Provider>
    </HelmetProvider>
    </MainContextProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
