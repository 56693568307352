import { memo } from "react";
import classNames from "./loadingAnimation.module.scss";
import connect from "../../static/images/footerIcons/connect.svg";
import email from "../../static/images/footerIcons/email.svg";
import insta from "../../static/images/footerIcons/insta.svg";
import telegram from "../../static/images/footerIcons/telegram.svg";
import whatsapp from "../../static/images/footerIcons/whatsapp.svg";
import youtube from "../../static/images/footerIcons/youtube.svg";
import careers from "../../static/images/footerIcons/careers.svg";
import terms from "../../static/images/footerIcons/terms.svg";
import investors from "../../static/images/footerIcons/investors.svg";
import media from "../../static/images/footerIcons/media.svg";
import news from "../../static/images/footerIcons/news.svg";
import privacy from "../../static/images/footerIcons/privacy.svg";

function HomeFooterMenus({ refIn }: { refIn: any }) {
  return (
    <>
      <section className={classNames.footerMenu} ref={refIn}>
        <div className={classNames.subMenu}>
          <div className={classNames.menuItem}>Engage With Us</div>
          <div className={classNames.submenuList}>
            <div className={classNames.submenuItem}>
              <img src={insta} alt="" />
              <span>Instagram</span>
            </div>
            <div className={classNames.submenuItem}>
              <img src={youtube} alt="" />
              <span>Youtube</span>
            </div>
            <div className={classNames.submenuItem}>
              <img src={whatsapp} alt="" />
              <span>WhatsApp</span>
            </div>
            <div className={classNames.submenuItem}>
              <img src={connect} alt="" />
              <span>Connection</span>
            </div>
            <div className={classNames.submenuItem}>
              <img src={telegram} alt="" />
              <span>Telegram</span>
            </div>
            <div className={classNames.submenuItem}>
              <img src={email} alt="" />
              <span>Email</span>
            </div>
          </div>
        </div>
        <div className={classNames.subMenu}>
          <div className={classNames.menuItem}>About IndianOTC</div>
          <div className={classNames.submenuList}>
            <div className={classNames.submenuItem}>
              <img src={news} alt="" />
              <span>News</span>
            </div>
            <div className={classNames.submenuItem}>
              <img src={media} alt="" />
              <span>Media Assets</span>
            </div>
            <div className={classNames.submenuItem}>
              <img src={terms} alt="" />
              <span>Terms Of Use</span>
            </div>
            <div className={classNames.submenuItem}>
              <img src={privacy} alt="" />
              <span>Privacy Policy</span>
            </div>
            <div className={classNames.submenuItem}>
              <img src={careers} alt="" />
              <span>Careers</span>
            </div>
            <div className={classNames.submenuItem}>
              <img src={investors} alt="" />
              <span>Investors</span>
            </div>
          </div>
        </div>
        <div className={classNames.subMenu}>
          <div className={classNames.menuItem}>Popular Services</div>
          <div className={classNames.submenuListCol}>
            <div className={classNames.submenuItem}>
              <span>Buy Bitcoin</span>
            </div>
            <div className={classNames.submenuItem}>
              <span>Buy Ethereum</span>
            </div>
            <div className={classNames.submenuItem}>
              <span>Buy USDT</span>
            </div>
            <div className={classNames.submenuItem}>
              <span>Buy Indra</span>
            </div>
            <div className={classNames.submenuItem}>
              <span>Buy Funds</span>
            </div>
            <div className={classNames.submenuItem}>
              <span>Buy Solana</span>
            </div>
            <div className={classNames.submenuItem}>
              <span>Buy Dogecoin</span>
            </div>
            <div className={classNames.submenuItem}>
              <span>Sell Bitcoin</span>
            </div>
            <div className={classNames.submenuItem}>
              <span>Sell Ethereum</span>
            </div>
            <div className={classNames.submenuItem}>
              <span>Sell USDT</span>
            </div>
            <div className={classNames.submenuItem}>
              <span>Sell Indra</span>
            </div>
            <div className={classNames.submenuItem}>
              <span>Sell Funds</span>
            </div>
            <div className={classNames.submenuItem}>
              <span>Sell Solana</span>
            </div>
            <div className={classNames.submenuItem}>
              <span>Sell Dogecoin</span>
            </div>
            <div className={classNames.submenuItem}>
              <span>Invest In FundCoins</span>
            </div>
            <div className={classNames.submenuItem}>
              <span>Collect RainDrops</span>
            </div>
            <div className={classNames.submenuItem}>
              <span>Create FundCoin</span>
            </div>
            <div className={classNames.submenuItem}>
              <span>List New Token</span>
            </div>
            <div className={classNames.submenuItem}>
              <span>Checkout With Crypto</span>
            </div>
            <div className={classNames.submenuItem}>
              <span>Join MarketWatchers</span>
            </div>
            <div className={classNames.submenuItem}>
              <span>IndianOTC Wealth</span>
            </div>
          </div>
        </div>
      </section>
      <div className={classNames.footerCopyright}>
        <span>All Rights Reserved 2022 IndianOTC.com</span>
      </div>
    </>
  );
}
export default memo(HomeFooterMenus);
