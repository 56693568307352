import { APP_CODE } from "../configs";
import CryptoJS from "crypto-js";

const KEY = "login";
export function loadState() {
  try {
    const serializedState = localStorage.getItem(KEY);
    if (!serializedState) return undefined;
    const decrypted = CryptoJS.AES.decrypt(serializedState, APP_CODE);
    return { loginData: JSON.parse(decrypted.toString(CryptoJS.enc.Utf8)) };
  } catch (e) {
    return undefined;
  }
}

export async function saveState(state: any) {
  try {
    const serializedState = JSON.stringify(state);
    const encrypted = CryptoJS.AES.encrypt(serializedState, APP_CODE);
    localStorage.setItem(KEY, encrypted.toString());
  } catch (e) {
    // Ignore
  }
}
