import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import AuctionsPage from "./pages/AuctionsPage";
import FounderPage from "./pages/FounderPage";
import FundsMarketSubTypesPage from "./pages/FundsMarketSubTypesPage";
import FundsMarketTypesPage from "./pages/FundsMarketTypesPage";
import GetStartedPage from "./pages/GetStartedPage";
import HomePage from "./pages/HomePage";
import AboutPage from "./pages/AboutPage";
import OTCHomePage from "./pages/OTCHomePage"
import InstitutionalPreRegPage from "./pages/InstitutionalPreRegPage";
import LandingPage from "./pages/LandingPage";
import OTCBuySellPage from "./pages/OTCBuySellPage";
import OTCFundPage from "./pages/OTCFundPage";
// import OTCVaultsPage from "./pages/OTCVaultsPage";
import ProspectusPage from "./pages/ProspectusPage";
import TerminalsPage from "./pages/TerminalsPage";
import ContactUs from "./pages/ContactusPage";
import PartnerShips from "./pages/partnershipsPage";

function MyRoutes() {
  return (
    <BrowserRouter>
      <Routes>
        {/* <Route path="/" element={<AuctionsPage />} /> */}
        <Route path="/" element={<Navigate to="/about/home" />} />
        <Route path="/about">
          <Route path="home" element={<AboutPage />} />
          <Route path="company" element={<HomePage />} />
          <Route path='contact' element={<ContactUs />} />
          <Route path='contact'>
            <Route path="partnerships" element={<PartnerShips />} />
          </Route>
        </Route>
        <Route path="/trading">
          <Route path="otcdesk" element={<OTCHomePage />} />
          <Route path="fund" element={<OTCFundPage />} />
          {/* <Route path="vaults" element={<OTCVaultsPage />} /> */}
          <Route path="markets" element={<LandingPage />} />
          <Route path="tokenSwap" element={<OTCBuySellPage isBuy />} />
          <Route index element={<LandingPage />} />
        </Route>
        <Route path="/terminals">
          <Route path=":base/:quote" element={<TerminalsPage />} />
          <Route index element={<Navigate to="/terminals/BTC/USDT" />} />
        </Route>
        <Route path="/funds">
          <Route path="market/types">
            <Route path=":subtype" element={<FundsMarketSubTypesPage />} />
            <Route path=":subtype/:pathId" element={<ProspectusPage />} />
            <Route index element={<FundsMarketTypesPage />} />
          </Route>
          <Route index element={<Navigate to="/funds/market/types" />} />
        </Route>
        <Route path="/getstarted">
          <Route path=":type" element={<GetStartedPage />} />
          <Route index element={<GetStartedPage />} />
        </Route>

        <Route path="/founders/:founder" element={<FounderPage />} />
        <Route
          path="/wealth/funds/preregister"
          element={<InstitutionalPreRegPage />}
        />
      </Routes>
    </BrowserRouter>
  );
}

export default MyRoutes;
